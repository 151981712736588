import React from "react";

export function Hakkimizda() {
  return (
    <div className="container-fluid mainContent py-5 text-white text-center">
      <h1 className="display-6 mb-3">Hakkımızda</h1>
      medrese.online platformu, Hadis ve Siyer Araştırmaları tarafından, Online
      Gramer Köşkü dersleri kapsamında geliştirilmektedir.
      <hr />
      <div>
        <img
          src="/assets/images/online-gramer-logo.png"
          alt="Online Gramer Köşkü"
          width="200"
        />
      </div>
      <div className="socialIcon">
        <a
          href="https://twitter.com/OnlineMektebi"
          className="socialIcon"
          target="_blank"
        >
          <img
            src="/assets/images/twitter-icon.svg"
            alt="twitter"
            target="_blank"
          />
          twitter.com/OnlineMektebi
        </a>
      </div>
      <hr />
      <div>
        <img
          src="/assets/images/hadis-siyer-logo.png"
          alt="Hadis ve Siyer Araştırmaları"
          width="200"
          align="center"
        />
        <a
          href="https://twitter.com/hadis_mektebi"
          className="socialIcon"
          target="_blank"
        >
          <img
            src="/assets/images/twitter-icon.svg"
            alt="twitter"
            target="_blank"
          />
          twitter.com/hadis_mektebi
        </a>
        <a
          href="https://instagram.com/hadisvesiyerarastirmalari"
          className="socialIcon"
          target="_blank"
        >
          <img src="/assets/images/instagram-icon.svg" alt="instagram" />
          instagram.com/hadisvesiyerarastirmalari
        </a>
        <a
          href="https://youtube.com/c/HAD%C4%B0SMEKTEB%C4%B0"
          className="socialIcon"
          target="_blank"
        >
          <img src="/assets/images/youtube.svg" alt="youtube" />
          Hadis Mektebi
        </a>
        <a
          href="https://www.facebook.com/people/Hadis-Mektebi/100065450264515/"
          className="socialIcon"
          target="_blank"
        >
          <img src="/assets/images/facebook.svg" alt="facebook" />
          Hadis Mektebi
        </a>
      </div>
    </div>
  );
}
