import React, { useEffect, useState } from "react";
import { ReactTree } from "@naisutech/react-tree";

let data = [
  {
    id: 0,
    parentId: null,
    label: "İ'rab Şeması",
  },
  {
    id: 1,
    parentId: 0,
    label: "İsim",
  },
  {
    id: 2,
    parentId: 0,
    label: "Fiil",
  },
  {
    id: 21,
    parentId: 2,
    label: "Mazi",
  },
  {
    id: 22,
    parentId: 2,
    label: "Muzari",
  },
  {
    id: 23,
    parentId: 2,
    label: "Emir",
  },
  {
    id: 3,
    parentId: 0,
    label: "Harf",
  },

  {
    id: 11,
    parentId: 1,
    label: "Müfred",
  },

  {
    id: 111,
    parentId: 11,
    label: "Merfu",
  },
  {
    id: 112,
    parentId: 11,
    label: "Mensub",
  },
  {
    id: 113,
    parentId: 11,
    label: "Mecrur",
  },
  {
    id: 1111,
    parentId: 111,
    label: "Fâil",
  },
  {
    id: 1011,
    parentId: 1111,
    label: "Zahir",
  },
  {
    id: 1012,
    parentId: 1111,
    label: "Zamir",
  },
  {
    id: 1112,
    parentId: 111,
    label: "Nâib-i Fâil",
  },
  {
    id: 1311,
    parentId: 1112,
    label: "Zahir",
  },
  {
    id: 1312,
    parentId: 1112,
    label: "Zamir",
  },
  {
    id: 1113,
    parentId: 111,
    label: "Mübtedâ",
  },
  {
    id: 1911,
    parentId: 1113,
    label: "Zahir",
  },
  {
    id: 1912,
    parentId: 1113,
    label: "Zamir",
  },
  {
    id: 1114,
    parentId: 111,
    label: "Haber",
  },
  {
    id: 1091,
    parentId: 1114,
    label: "Müfred",
  },
  {
    id: 1092,
    parentId: 1114,
    label: "Müfred Olmayan",
  },
  {
    id: 1093,
    parentId: 1092,
    label: "Cümle",
  },
  {
    id: 2093,
    parentId: 1093,
    label: "İsim Cümlesi",
  },
  {
    id: 2094,
    parentId: 1093,
    label: "Fiil Cümlesi",
  },
  {
    id: 1094,
    parentId: 1092,
    label: "Şibh-i Cümle",
  },
  {
    id: 2095,
    parentId: 1094,
    label: "Zarf",
  },
  {
    id: 2096,
    parentId: 1094,
    label: "Car-ı Mecrur",
  },
  {
    id: 1115,
    parentId: 111,
    label: 'إسم "كان" وأخواتها',
  },
  {
    id: 1116,
    parentId: 111,
    label: 'خبر "إن" وأخواتها',
  },
  {
    id: 1117,
    parentId: 111,
    label: "Tabiiler",
  },
  {
    id: 3200,
    parentId: 1117,
    label: "Sıfat",
  },
  {
    id: 3201,
    parentId: 1117,
    label: "Tekid",
  },
  {
    id: 3202,
    parentId: 1117,
    label: "Atıf",
  },
  {
    id: 3203,
    parentId: 1117,
    label: "Bedel",
  },

  {
    id: 1121,
    parentId: 112,
    label: "Mef'ûlu Bihi",
  },

  {
    id: 2000,
    parentId: 1121,
    label: "Zahir",
  },
  {
    id: 2001,
    parentId: 1121,
    label: "Zamir",
  },

  {
    id: 1122,
    parentId: 112,
    label: "Mef'ûlu Fihi",
  },
  {
    id: 1123,
    parentId: 112,
    label: "Mef'ûlu Lehu",
  },
  {
    id: 1124,
    parentId: 112,
    label: "Mef'ûlu Me'ahu",
  },
  {
    id: 1125,
    parentId: 112,
    label: "Mef'ûlu Mutlak",
  },

  {
    id: 2002,
    parentId: 1125,
    label: "Tekidi",
  },
  {
    id: 2003,
    parentId: 1125,
    label: "Nev'i",
  },
  {
    id: 2004,
    parentId: 1125,
    label: "Adedi",
  },

  {
    id: 1126,
    parentId: 112,
    label: "Hal",
  },
  {
    id: 1127,
    parentId: 112,
    label: "Temyiz",
  },

  {
    id: 2005,
    parentId: 1127,
    label: "Temyiz-i Zati",
  },
  {
    id: 2006,
    parentId: 1127,
    label: "Temyiz-i Nisbi",
  },

  {
    id: 2007,
    parentId: 2006,
    label: "Muhavvel",
  },

  {
    id: 2020,
    parentId: 2007,
    label: "Fâilden",
  },
  {
    id: 2021,
    parentId: 2007,
    label: "Mef'ûlden",
  },
  {
    id: 2022,
    parentId: 2007,
    label: "Mübtedâdan",
  },

  {
    id: 2011,
    parentId: 2006,
    label: "Ğayrı Muhavvel",
  },

  {
    id: 2012,
    parentId: 112,
    label: "Münâdâ",
  },

  {
    id: 2023,
    parentId: 2012,
    label: "Müfred-i Alem",
  },
  {
    id: 2024,
    parentId: 2012,
    label: "Nekire-i Maksude",
  },
  {
    id: 2025,
    parentId: 2012,
    label: "Nekire-i Gayrı Maksude",
  },
  {
    id: 2026,
    parentId: 2012,
    label: "Mudaf",
  },
  {
    id: 2027,
    parentId: 2012,
    label: "Şibh-i Mudaf",
  },

  {
    id: 1129,
    parentId: 112,
    label: "Müstesnâ",
  },

  {
    id: 11291,
    parentId: 112,
    label: 'إسم "لا" النافية للجنس',
  },

  {
    id: 2028,
    parentId: 11291,
    label: "Müfred",
  },
  {
    id: 2029,
    parentId: 11291,
    label: "Nekireye İdafe Edilen",
  },
  {
    id: 2030,
    parentId: 11291,
    label: "Şibh-i Mudaf",
  },

  {
    id: 11292,
    parentId: 112,
    label: 'خبر "كان" وأخواتها',
  },
  {
    id: 11293,
    parentId: 112,
    label: 'إسم "إن" وأخواتها',
  },
  {
    id: 11294,
    parentId: 112,
    label: "Tabiiler",
  },

  {
    id: 2031,
    parentId: 11294,
    label: "Sıfat",
  },
  {
    id: 2032,
    parentId: 11294,
    label: "Tekid",
  },
  {
    id: 2033,
    parentId: 11294,
    label: "Atıf",
  },
  {
    id: 2034,
    parentId: 11294,
    label: "Bedel",
  },

  {
    id: 1130,
    parentId: 113,
    label: "Car-ı Mecrur",
  },
  {
    id: 1131,
    parentId: 113,
    label: "Muzâfu'n-İleyh",
  },

  {
    id: 2035,
    parentId: 1131,
    label: "من",
  },
  {
    id: 2036,
    parentId: 1131,
    label: "في",
  },
  {
    id: 2037,
    parentId: 1131,
    label: "إلى",
  },

  {
    id: 1132,
    parentId: 113,
    label: "Tabiiler",
  },
  {
    id: 2038,
    parentId: 1132,
    label: "Sıfat",
  },
  {
    id: 2039,
    parentId: 1132,
    label: "Tekid",
  },
  {
    id: 2040,
    parentId: 1132,
    label: "Atıf",
  },
  {
    id: 2041,
    parentId: 1132,
    label: "Bedel",
  },

  {
    id: 911,
    parentId: 1,
    label: "Tesniye",
  },

  {
    id: 9111,
    parentId: 911,
    label: "Merfu",
  },
  {
    id: 9112,
    parentId: 911,
    label: "Mensub",
  },
  {
    id: 9113,
    parentId: 911,
    label: "Mecrur",
  },
  {
    id: 91111,
    parentId: 9111,
    label: "Fâil",
  },
  {
    id: 91011,
    parentId: 91111,
    label: "Zahir",
  },
  {
    id: 91012,
    parentId: 91111,
    label: "Zamir",
  },
  {
    id: 91112,
    parentId: 9111,
    label: "Nâib-i Fâil",
  },
  {
    id: 91311,
    parentId: 91112,
    label: "Zahir",
  },
  {
    id: 91312,
    parentId: 91112,
    label: "Zamir",
  },
  {
    id: 91113,
    parentId: 9111,
    label: "Mübtedâ",
  },
  {
    id: 91911,
    parentId: 91113,
    label: "Zahir",
  },
  {
    id: 91912,
    parentId: 91113,
    label: "Zamir",
  },
  {
    id: 91114,
    parentId: 9111,
    label: "Haber",
  },
  {
    id: 91091,
    parentId: 91114,
    label: "Müfred",
  },
  {
    id: 91092,
    parentId: 91114,
    label: "Müfred Olmayan",
  },
  {
    id: 91093,
    parentId: 91092,
    label: "Cümle",
  },
  {
    id: 92093,
    parentId: 91093,
    label: "İsim Cümlesi",
  },
  {
    id: 92094,
    parentId: 91093,
    label: "Fiil Cümlesi",
  },
  {
    id: 91094,
    parentId: 91092,
    label: "Şibh-i Cümle",
  },
  {
    id: 92095,
    parentId: 91094,
    label: "Zarf",
  },
  {
    id: 92096,
    parentId: 91094,
    label: "Car-ı Mecrur",
  },
  {
    id: 91115,
    parentId: 9111,
    label: 'إسم "كان" وأخواتها',
  },
  {
    id: 91116,
    parentId: 9111,
    label: 'خبر "إن" وأخواتها',
  },
  {
    id: 91117,
    parentId: 9111,
    label: "Tabiiler",
  },
  {
    id: 93200,
    parentId: 91117,
    label: "Sıfat",
  },
  {
    id: 93201,
    parentId: 91117,
    label: "Tekid",
  },
  {
    id: 93202,
    parentId: 91117,
    label: "Atıf",
  },
  {
    id: 93203,
    parentId: 91117,
    label: "Bedel",
  },

  {
    id: 91121,
    parentId: 9112,
    label: "Mef'ûlu Bihi",
  },

  {
    id: 92000,
    parentId: 91121,
    label: "Zahir",
  },
  {
    id: 92001,
    parentId: 91121,
    label: "Zamir",
  },

  {
    id: 91122,
    parentId: 9112,
    label: "Mef'ûlu Fihi",
  },
  {
    id: 91123,
    parentId: 9112,
    label: "Mef'ûlu Lehu",
  },
  {
    id: 91124,
    parentId: 9112,
    label: "Mef'ûlu Me'ahu",
  },
  {
    id: 91125,
    parentId: 9112,
    label: "Mef'ûlu Mutlak",
  },

  {
    id: 92002,
    parentId: 91125,
    label: "Tekidi",
  },
  {
    id: 92003,
    parentId: 91125,
    label: "Nev'i",
  },
  {
    id: 92004,
    parentId: 91125,
    label: "Adedi",
  },

  {
    id: 91126,
    parentId: 9112,
    label: "Hal",
  },
  {
    id: 91127,
    parentId: 9112,
    label: "Temyiz",
  },

  {
    id: 92005,
    parentId: 91127,
    label: "Temyiz-i Zati",
  },
  {
    id: 92006,
    parentId: 91127,
    label: "Temyiz-i Nisbi",
  },

  {
    id: 92007,
    parentId: 92006,
    label: "Muhavvel",
  },

  {
    id: 92020,
    parentId: 92007,
    label: "Fâilden",
  },
  {
    id: 92021,
    parentId: 92007,
    label: "Mef'ûlden",
  },
  {
    id: 92022,
    parentId: 92007,
    label: "Mübtedâdan",
  },

  {
    id: 92011,
    parentId: 92006,
    label: "Ğayrı Muhavvel",
  },

  {
    id: 92012,
    parentId: 9112,
    label: "Münâdâ",
  },

  {
    id: 92023,
    parentId: 92012,
    label: "Müfred-i Alem",
  },
  {
    id: 92024,
    parentId: 92012,
    label: "Nekire-i Maksude",
  },
  {
    id: 92025,
    parentId: 92012,
    label: "Nekire-i Gayrı Maksude",
  },
  {
    id: 92026,
    parentId: 92012,
    label: "Mudaf",
  },
  {
    id: 92027,
    parentId: 92012,
    label: "Şibh-i Mudaf",
  },

  {
    id: 91129,
    parentId: 9112,
    label: "Müstesnâ",
  },

  {
    id: 911291,
    parentId: 9112,
    label: 'إسم "لا" النافية للجنس',
  },

  {
    id: 92028,
    parentId: 911291,
    label: "Müfred",
  },
  {
    id: 92029,
    parentId: 911291,
    label: "Nekireye İdafe Edilen",
  },
  {
    id: 92030,
    parentId: 911291,
    label: "Şibh-i Mudaf",
  },

  {
    id: 911292,
    parentId: 9112,
    label: 'خبر "كان" وأخواتها',
  },
  {
    id: 911293,
    parentId: 9112,
    label: 'إسم "إن" وأخواتها',
  },
  {
    id: 911294,
    parentId: 9112,
    label: "Tabiiler",
  },

  {
    id: 92031,
    parentId: 911294,
    label: "Sıfat",
  },
  {
    id: 92032,
    parentId: 911294,
    label: "Tekid",
  },
  {
    id: 92033,
    parentId: 911294,
    label: "Atıf",
  },
  {
    id: 92034,
    parentId: 911294,
    label: "Bedel",
  },

  {
    id: 91130,
    parentId: 9113,
    label: "Car-ı Mecrur",
  },
  {
    id: 91131,
    parentId: 9113,
    label: "Muzâfu'n-İleyh",
  },

  {
    id: 92035,
    parentId: 91131,
    label: "من",
  },
  {
    id: 92036,
    parentId: 91131,
    label: "في",
  },
  {
    id: 92037,
    parentId: 91131,
    label: "إلى",
  },

  {
    id: 91132,
    parentId: 9113,
    label: "Tabiiler",
  },
  {
    id: 92038,
    parentId: 91132,
    label: "Sıfat",
  },
  {
    id: 92039,
    parentId: 91132,
    label: "Tekid",
  },
  {
    id: 92040,
    parentId: 91132,
    label: "Atıf",
  },
  {
    id: 92041,
    parentId: 91132,
    label: "Bedel",
  },
  {
    id: 811,
    parentId: 1,
    label: "Cem Müzekker Salım",
  },

  {
    id: 8111,
    parentId: 811,
    label: "Merfu",
  },
  {
    id: 8112,
    parentId: 811,
    label: "Mensub",
  },
  {
    id: 8113,
    parentId: 811,
    label: "Mecrur",
  },
  {
    id: 81111,
    parentId: 8111,
    label: "Fâil",
  },
  {
    id: 81011,
    parentId: 81111,
    label: "Zahir",
  },
  {
    id: 81012,
    parentId: 81111,
    label: "Zamir",
  },
  {
    id: 81112,
    parentId: 8111,
    label: "Nâib-i Fâil",
  },
  {
    id: 81311,
    parentId: 81112,
    label: "Zahir",
  },
  {
    id: 81312,
    parentId: 81112,
    label: "Zamir",
  },
  {
    id: 81113,
    parentId: 8111,
    label: "Mübtedâ",
  },
  {
    id: 81911,
    parentId: 81113,
    label: "Zahir",
  },
  {
    id: 81912,
    parentId: 81113,
    label: "Zamir",
  },
  {
    id: 81114,
    parentId: 8111,
    label: "Haber",
  },
  {
    id: 81091,
    parentId: 81114,
    label: "Müfred",
  },
  {
    id: 81092,
    parentId: 81114,
    label: "Müfred Olmayan",
  },
  {
    id: 81093,
    parentId: 81092,
    label: "Cümle",
  },
  {
    id: 82093,
    parentId: 81093,
    label: "İsim Cümlesi",
  },
  {
    id: 82094,
    parentId: 81093,
    label: "Fiil Cümlesi",
  },
  {
    id: 81094,
    parentId: 81092,
    label: "Şibh-i Cümle",
  },
  {
    id: 82095,
    parentId: 81094,
    label: "Zarf",
  },
  {
    id: 82096,
    parentId: 81094,
    label: "Car-ı Mecrur",
  },
  {
    id: 81115,
    parentId: 8111,
    label: 'إسم "كان" وأخواتها',
  },
  {
    id: 81116,
    parentId: 8111,
    label: 'خبر "إن" وأخواتها',
  },
  {
    id: 81117,
    parentId: 8111,
    label: "Tabiiler",
  },
  {
    id: 83200,
    parentId: 81117,
    label: "Sıfat",
  },
  {
    id: 83201,
    parentId: 81117,
    label: "Tekid",
  },
  {
    id: 83202,
    parentId: 81117,
    label: "Atıf",
  },
  {
    id: 83203,
    parentId: 81117,
    label: "Bedel",
  },

  {
    id: 81121,
    parentId: 8112,
    label: "Mef'ûlu Bihi",
  },

  {
    id: 82000,
    parentId: 81121,
    label: "Zahir",
  },
  {
    id: 82001,
    parentId: 81121,
    label: "Zamir",
  },

  {
    id: 81122,
    parentId: 8112,
    label: "Mef'ûlu Fihi",
  },
  {
    id: 81123,
    parentId: 8112,
    label: "Mef'ûlu Lehu",
  },
  {
    id: 81124,
    parentId: 8112,
    label: "Mef'ûlu Me'ahu",
  },
  {
    id: 81125,
    parentId: 8112,
    label: "Mef'ûlu Mutlak",
  },

  {
    id: 82002,
    parentId: 81125,
    label: "Tekidi",
  },
  {
    id: 82003,
    parentId: 81125,
    label: "Nev'i",
  },
  {
    id: 82004,
    parentId: 81125,
    label: "Adedi",
  },

  {
    id: 81126,
    parentId: 8112,
    label: "Hal",
  },
  {
    id: 81127,
    parentId: 8112,
    label: "Temyiz",
  },

  {
    id: 82005,
    parentId: 81127,
    label: "Temyiz-i Zati",
  },
  {
    id: 82006,
    parentId: 81127,
    label: "Temyiz-i Nisbi",
  },

  {
    id: 82007,
    parentId: 82006,
    label: "Muhavvel",
  },

  {
    id: 82020,
    parentId: 82007,
    label: "Fâilden",
  },
  {
    id: 82021,
    parentId: 82007,
    label: "Mef'ûlden",
  },
  {
    id: 82022,
    parentId: 82007,
    label: "Mübtedâdan",
  },

  {
    id: 82011,
    parentId: 82006,
    label: "Ğayrı Muhavvel",
  },

  {
    id: 82012,
    parentId: 8112,
    label: "Münâdâ",
  },

  {
    id: 82023,
    parentId: 82012,
    label: "Müfred-i Alem",
  },
  {
    id: 82024,
    parentId: 82012,
    label: "Nekire-i Maksude",
  },
  {
    id: 82025,
    parentId: 82012,
    label: "Nekire-i Gayrı Maksude",
  },
  {
    id: 82026,
    parentId: 82012,
    label: "Mudaf",
  },
  {
    id: 82027,
    parentId: 82012,
    label: "Şibh-i Mudaf",
  },

  {
    id: 81129,
    parentId: 8112,
    label: "Müstesnâ",
  },

  {
    id: 811291,
    parentId: 8112,
    label: 'إسم "لا" النافية للجنس',
  },

  {
    id: 82028,
    parentId: 811291,
    label: "Müfred",
  },
  {
    id: 82029,
    parentId: 811291,
    label: "Nekireye İdafe Edilen",
  },
  {
    id: 82030,
    parentId: 811291,
    label: "Şibh-i Mudaf",
  },

  {
    id: 811292,
    parentId: 8112,
    label: 'خبر "كان" وأخواتها',
  },
  {
    id: 811293,
    parentId: 8112,
    label: 'إسم "إن" وأخواتها',
  },
  {
    id: 811294,
    parentId: 8112,
    label: "Tabiiler",
  },

  {
    id: 82031,
    parentId: 811294,
    label: "Sıfat",
  },
  {
    id: 82032,
    parentId: 811294,
    label: "Tekid",
  },
  {
    id: 82033,
    parentId: 811294,
    label: "Atıf",
  },
  {
    id: 82034,
    parentId: 811294,
    label: "Bedel",
  },

  {
    id: 81130,
    parentId: 8113,
    label: "Car-ı Mecrur",
  },
  {
    id: 81131,
    parentId: 8113,
    label: "Muzâfu'n-İleyh",
  },

  {
    id: 82035,
    parentId: 81131,
    label: "من",
  },
  {
    id: 82036,
    parentId: 81131,
    label: "في",
  },
  {
    id: 82037,
    parentId: 81131,
    label: "إلى",
  },

  {
    id: 81132,
    parentId: 8113,
    label: "Tabiiler",
  },
  {
    id: 82038,
    parentId: 81132,
    label: "Sıfat",
  },
  {
    id: 82039,
    parentId: 81132,
    label: "Tekid",
  },
  {
    id: 82040,
    parentId: 81132,
    label: "Atıf",
  },
  {
    id: 82041,
    parentId: 81132,
    label: "Bedel",
  },
  {
    id: 711,
    parentId: 1,
    label: "Cem Müennes Salım",
  },

  {
    id: 7111,
    parentId: 711,
    label: "Merfu",
  },
  {
    id: 7112,
    parentId: 711,
    label: "Mensub",
  },
  {
    id: 7113,
    parentId: 711,
    label: "Mecrur",
  },
  {
    id: 71111,
    parentId: 7111,
    label: "Fâil",
  },
  {
    id: 71011,
    parentId: 71111,
    label: "Zahir",
  },
  {
    id: 71012,
    parentId: 71111,
    label: "Zamir",
  },
  {
    id: 71112,
    parentId: 7111,
    label: "Nâib-i Fâil",
  },
  {
    id: 71311,
    parentId: 71112,
    label: "Zahir",
  },
  {
    id: 71312,
    parentId: 71112,
    label: "Zamir",
  },
  {
    id: 71113,
    parentId: 7111,
    label: "Mübtedâ",
  },
  {
    id: 71911,
    parentId: 71113,
    label: "Zahir",
  },
  {
    id: 71912,
    parentId: 71113,
    label: "Zamir",
  },
  {
    id: 71114,
    parentId: 7111,
    label: "Haber",
  },
  {
    id: 71091,
    parentId: 71114,
    label: "Müfred",
  },
  {
    id: 71092,
    parentId: 71114,
    label: "Müfred Olmayan",
  },
  {
    id: 71093,
    parentId: 71092,
    label: "Cümle",
  },
  {
    id: 72093,
    parentId: 71093,
    label: "İsim Cümlesi",
  },
  {
    id: 72094,
    parentId: 71093,
    label: "Fiil Cümlesi",
  },
  {
    id: 71094,
    parentId: 71092,
    label: "Şibh-i Cümle",
  },
  {
    id: 72095,
    parentId: 71094,
    label: "Zarf",
  },
  {
    id: 72096,
    parentId: 71094,
    label: "Car-ı Mecrur",
  },
  {
    id: 71115,
    parentId: 7111,
    label: 'إسم "كان" وأخواتها',
  },
  {
    id: 71116,
    parentId: 7111,
    label: 'خبر "إن" وأخواتها',
  },
  {
    id: 71117,
    parentId: 7111,
    label: "Tabiiler",
  },
  {
    id: 73200,
    parentId: 71117,
    label: "Sıfat",
  },
  {
    id: 73201,
    parentId: 71117,
    label: "Tekid",
  },
  {
    id: 73202,
    parentId: 71117,
    label: "Atıf",
  },
  {
    id: 73203,
    parentId: 71117,
    label: "Bedel",
  },

  {
    id: 71121,
    parentId: 7112,
    label: "Mef'ûlu Bihi",
  },

  {
    id: 72000,
    parentId: 71121,
    label: "Zahir",
  },
  {
    id: 72001,
    parentId: 71121,
    label: "Zamir",
  },

  {
    id: 71122,
    parentId: 7112,
    label: "Mef'ûlu Fihi",
  },
  {
    id: 71123,
    parentId: 7112,
    label: "Mef'ûlu Lehu",
  },
  {
    id: 71124,
    parentId: 7112,
    label: "Mef'ûlu Me'ahu",
  },
  {
    id: 71125,
    parentId: 7112,
    label: "Mef'ûlu Mutlak",
  },

  {
    id: 72002,
    parentId: 71125,
    label: "Tekidi",
  },
  {
    id: 72003,
    parentId: 71125,
    label: "Nev'i",
  },
  {
    id: 72004,
    parentId: 71125,
    label: "Adedi",
  },

  {
    id: 71126,
    parentId: 7112,
    label: "Hal",
  },
  {
    id: 71127,
    parentId: 7112,
    label: "Temyiz",
  },

  {
    id: 72005,
    parentId: 71127,
    label: "Temyiz-i Zati",
  },
  {
    id: 72006,
    parentId: 71127,
    label: "Temyiz-i Nisbi",
  },

  {
    id: 72007,
    parentId: 72006,
    label: "Muhavvel",
  },

  {
    id: 72020,
    parentId: 72007,
    label: "Fâilden",
  },
  {
    id: 72021,
    parentId: 72007,
    label: "Mef'ûlden",
  },
  {
    id: 72022,
    parentId: 72007,
    label: "Mübtedâdan",
  },

  {
    id: 72011,
    parentId: 72006,
    label: "Ğayrı Muhavvel",
  },

  {
    id: 72012,
    parentId: 7112,
    label: "Münâdâ",
  },

  {
    id: 72023,
    parentId: 72012,
    label: "Müfred-i Alem",
  },
  {
    id: 72024,
    parentId: 72012,
    label: "Nekire-i Maksude",
  },
  {
    id: 72025,
    parentId: 72012,
    label: "Nekire-i Gayrı Maksude",
  },
  {
    id: 72026,
    parentId: 72012,
    label: "Mudaf",
  },
  {
    id: 72027,
    parentId: 72012,
    label: "Şibh-i Mudaf",
  },

  {
    id: 71129,
    parentId: 7112,
    label: "Müstesnâ",
  },

  {
    id: 711291,
    parentId: 7112,
    label: 'إسم "لا" النافية للجنس',
  },

  {
    id: 72028,
    parentId: 711291,
    label: "Müfred",
  },
  {
    id: 72029,
    parentId: 711291,
    label: "Nekireye İdafe Edilen",
  },
  {
    id: 72030,
    parentId: 711291,
    label: "Şibh-i Mudaf",
  },

  {
    id: 711292,
    parentId: 7112,
    label: 'خبر "كان" وأخواتها',
  },
  {
    id: 711293,
    parentId: 7112,
    label: 'إسم "إن" وأخواتها',
  },
  {
    id: 711294,
    parentId: 7112,
    label: "Tabiiler",
  },

  {
    id: 72031,
    parentId: 711294,
    label: "Sıfat",
  },
  {
    id: 72032,
    parentId: 711294,
    label: "Tekid",
  },
  {
    id: 72033,
    parentId: 711294,
    label: "Atıf",
  },
  {
    id: 72034,
    parentId: 711294,
    label: "Bedel",
  },

  {
    id: 71130,
    parentId: 7113,
    label: "Car-ı Mecrur",
  },
  {
    id: 71131,
    parentId: 7113,
    label: "Muzâfu'n-İleyh",
  },

  {
    id: 72035,
    parentId: 71131,
    label: "من",
  },
  {
    id: 72036,
    parentId: 71131,
    label: "في",
  },
  {
    id: 72037,
    parentId: 71131,
    label: "إلى",
  },

  {
    id: 71132,
    parentId: 7113,
    label: "Tabiiler",
  },
  {
    id: 72038,
    parentId: 71132,
    label: "Sıfat",
  },
  {
    id: 72039,
    parentId: 71132,
    label: "Tekid",
  },
  {
    id: 72040,
    parentId: 71132,
    label: "Atıf",
  },
  {
    id: 72041,
    parentId: 71132,
    label: "Bedel",
  },
  {
    id: 611,
    parentId: 1,
    label: "Cem Mükesser",
  },

  {
    id: 6111,
    parentId: 611,
    label: "Merfu",
  },
  {
    id: 6112,
    parentId: 611,
    label: "Mensub",
  },
  {
    id: 6113,
    parentId: 611,
    label: "Mecrur",
  },
  {
    id: 61111,
    parentId: 6111,
    label: "Fâil",
  },
  {
    id: 61011,
    parentId: 61111,
    label: "Zahir",
  },
  {
    id: 61012,
    parentId: 61111,
    label: "Zamir",
  },
  {
    id: 61112,
    parentId: 6111,
    label: "Nâib-i Fâil",
  },
  {
    id: 61311,
    parentId: 61112,
    label: "Zahir",
  },
  {
    id: 61312,
    parentId: 61112,
    label: "Zamir",
  },
  {
    id: 61113,
    parentId: 6111,
    label: "Mübtedâ",
  },
  {
    id: 61911,
    parentId: 61113,
    label: "Zahir",
  },
  {
    id: 61912,
    parentId: 61113,
    label: "Zamir",
  },
  {
    id: 61114,
    parentId: 6111,
    label: "Haber",
  },
  {
    id: 61091,
    parentId: 61114,
    label: "Müfred",
  },
  {
    id: 61092,
    parentId: 61114,
    label: "Müfred Olmayan",
  },
  {
    id: 61093,
    parentId: 61092,
    label: "Cümle",
  },
  {
    id: 62093,
    parentId: 61093,
    label: "İsim Cümlesi",
  },
  {
    id: 62094,
    parentId: 61093,
    label: "Fiil Cümlesi",
  },
  {
    id: 61094,
    parentId: 61092,
    label: "Şibh-i Cümle",
  },
  {
    id: 62095,
    parentId: 61094,
    label: "Zarf",
  },
  {
    id: 62096,
    parentId: 61094,
    label: "Car-ı Mecrur",
  },
  {
    id: 61115,
    parentId: 6111,
    label: 'إسم "كان" وأخواتها',
  },
  {
    id: 61116,
    parentId: 6111,
    label: 'خبر "إن" وأخواتها',
  },
  {
    id: 61117,
    parentId: 6111,
    label: "Tabiiler",
  },
  {
    id: 63200,
    parentId: 61117,
    label: "Sıfat",
  },
  {
    id: 63201,
    parentId: 61117,
    label: "Tekid",
  },
  {
    id: 63202,
    parentId: 61117,
    label: "Atıf",
  },
  {
    id: 63203,
    parentId: 61117,
    label: "Bedel",
  },

  {
    id: 61121,
    parentId: 6112,
    label: "Mef'ûlu Bihi",
  },

  {
    id: 62000,
    parentId: 61121,
    label: "Zahir",
  },
  {
    id: 62001,
    parentId: 61121,
    label: "Zamir",
  },

  {
    id: 61122,
    parentId: 6112,
    label: "Mef'ûlu Fihi",
  },
  {
    id: 61123,
    parentId: 6112,
    label: "Mef'ûlu Lehu",
  },
  {
    id: 61124,
    parentId: 6112,
    label: "Mef'ûlu Me'ahu",
  },
  {
    id: 61125,
    parentId: 6112,
    label: "Mef'ûlu Mutlak",
  },

  {
    id: 62002,
    parentId: 61125,
    label: "Tekidi",
  },
  {
    id: 62003,
    parentId: 61125,
    label: "Nev'i",
  },
  {
    id: 62004,
    parentId: 61125,
    label: "Adedi",
  },

  {
    id: 61126,
    parentId: 6112,
    label: "Hal",
  },
  {
    id: 61127,
    parentId: 6112,
    label: "Temyiz",
  },

  {
    id: 62005,
    parentId: 61127,
    label: "Temyiz-i Zati",
  },
  {
    id: 62006,
    parentId: 61127,
    label: "Temyiz-i Nisbi",
  },

  {
    id: 62007,
    parentId: 62006,
    label: "Muhavvel",
  },

  {
    id: 62020,
    parentId: 62007,
    label: "Fâilden",
  },
  {
    id: 62021,
    parentId: 62007,
    label: "Mef'ûlden",
  },
  {
    id: 62022,
    parentId: 62007,
    label: "Mübtedâdan",
  },

  {
    id: 62011,
    parentId: 62006,
    label: "Ğayrı Muhavvel",
  },

  {
    id: 62012,
    parentId: 6112,
    label: "Münâdâ",
  },

  {
    id: 62023,
    parentId: 62012,
    label: "Müfred-i Alem",
  },
  {
    id: 62024,
    parentId: 62012,
    label: "Nekire-i Maksude",
  },
  {
    id: 62025,
    parentId: 62012,
    label: "Nekire-i Gayrı Maksude",
  },
  {
    id: 62026,
    parentId: 62012,
    label: "Mudaf",
  },
  {
    id: 62027,
    parentId: 62012,
    label: "Şibh-i Mudaf",
  },

  {
    id: 61129,
    parentId: 6112,
    label: "Müstesnâ",
  },

  {
    id: 611291,
    parentId: 6112,
    label: 'إسم "لا" النافية للجنس',
  },

  {
    id: 62028,
    parentId: 611291,
    label: "Müfred",
  },
  {
    id: 62029,
    parentId: 611291,
    label: "Nekireye İdafe Edilen",
  },
  {
    id: 62030,
    parentId: 611291,
    label: "Şibh-i Mudaf",
  },

  {
    id: 611292,
    parentId: 6112,
    label: 'خبر "كان" وأخواتها',
  },
  {
    id: 611293,
    parentId: 6112,
    label: 'إسم "إن" وأخواتها',
  },
  {
    id: 611294,
    parentId: 6112,
    label: "Tabiiler",
  },

  {
    id: 62031,
    parentId: 611294,
    label: "Sıfat",
  },
  {
    id: 62032,
    parentId: 611294,
    label: "Tekid",
  },
  {
    id: 62033,
    parentId: 611294,
    label: "Atıf",
  },
  {
    id: 62034,
    parentId: 611294,
    label: "Bedel",
  },

  {
    id: 61130,
    parentId: 6113,
    label: "Car-ı Mecrur",
  },
  {
    id: 61131,
    parentId: 6113,
    label: "Muzâfu'n-İleyh",
  },

  {
    id: 62035,
    parentId: 61131,
    label: "من",
  },
  {
    id: 62036,
    parentId: 61131,
    label: "في",
  },
  {
    id: 62037,
    parentId: 61131,
    label: "إلى",
  },

  {
    id: 61132,
    parentId: 6113,
    label: "Tabiiler",
  },
  {
    id: 62038,
    parentId: 61132,
    label: "Sıfat",
  },
  {
    id: 62039,
    parentId: 61132,
    label: "Tekid",
  },
  {
    id: 62040,
    parentId: 61132,
    label: "Atıf",
  },
  {
    id: 62041,
    parentId: 61132,
    label: "Bedel",
  },
  {
    id: 511,
    parentId: 1,
    label: "Ğayrı Munsarif",
  },

  {
    id: 5111,
    parentId: 511,
    label: "Merfu",
  },
  {
    id: 5112,
    parentId: 511,
    label: "Mensub",
  },
  {
    id: 5113,
    parentId: 511,
    label: "Mecrur",
  },
  {
    id: 51111,
    parentId: 5111,
    label: "Fâil",
  },
  {
    id: 51011,
    parentId: 51111,
    label: "Zahir",
  },
  {
    id: 51012,
    parentId: 51111,
    label: "Zamir",
  },
  {
    id: 51112,
    parentId: 5111,
    label: "Nâib-i Fâil",
  },
  {
    id: 51311,
    parentId: 51112,
    label: "Zahir",
  },
  {
    id: 51312,
    parentId: 51112,
    label: "Zamir",
  },
  {
    id: 51113,
    parentId: 5111,
    label: "Mübtedâ",
  },
  {
    id: 51911,
    parentId: 51113,
    label: "Zahir",
  },
  {
    id: 51912,
    parentId: 51113,
    label: "Zamir",
  },
  {
    id: 51114,
    parentId: 5111,
    label: "Haber",
  },
  {
    id: 51091,
    parentId: 51114,
    label: "Müfred",
  },
  {
    id: 51092,
    parentId: 51114,
    label: "Müfred Olmayan",
  },
  {
    id: 51093,
    parentId: 51092,
    label: "Cümle",
  },
  {
    id: 52093,
    parentId: 51093,
    label: "İsim Cümlesi",
  },
  {
    id: 52094,
    parentId: 51093,
    label: "Fiil Cümlesi",
  },
  {
    id: 51094,
    parentId: 51092,
    label: "Şibh-i Cümle",
  },
  {
    id: 52095,
    parentId: 51094,
    label: "Zarf",
  },
  {
    id: 52096,
    parentId: 51094,
    label: "Car-ı Mecrur",
  },
  {
    id: 51115,
    parentId: 5111,
    label: 'إسم "كان" وأخواتها',
  },
  {
    id: 51116,
    parentId: 5111,
    label: 'خبر "إن" وأخواتها',
  },
  {
    id: 51117,
    parentId: 5111,
    label: "Tabiiler",
  },
  {
    id: 53200,
    parentId: 51117,
    label: "Sıfat",
  },
  {
    id: 53201,
    parentId: 51117,
    label: "Tekid",
  },
  {
    id: 53202,
    parentId: 51117,
    label: "Atıf",
  },
  {
    id: 53203,
    parentId: 51117,
    label: "Bedel",
  },

  {
    id: 51121,
    parentId: 5112,
    label: "Mef'ûlu Bihi",
  },

  {
    id: 52000,
    parentId: 51121,
    label: "Zahir",
  },
  {
    id: 52001,
    parentId: 51121,
    label: "Zamir",
  },

  {
    id: 51122,
    parentId: 5112,
    label: "Mef'ûlu Fihi",
  },
  {
    id: 51123,
    parentId: 5112,
    label: "Mef'ûlu Lehu",
  },
  {
    id: 51124,
    parentId: 5112,
    label: "Mef'ûlu Me'ahu",
  },
  {
    id: 51125,
    parentId: 5112,
    label: "Mef'ûlu Mutlak",
  },

  {
    id: 52002,
    parentId: 51125,
    label: "Tekidi",
  },
  {
    id: 52003,
    parentId: 51125,
    label: "Nev'i",
  },
  {
    id: 52004,
    parentId: 51125,
    label: "Adedi",
  },

  {
    id: 51126,
    parentId: 5112,
    label: "Hal",
  },
  {
    id: 51127,
    parentId: 5112,
    label: "Temyiz",
  },

  {
    id: 52005,
    parentId: 51127,
    label: "Temyiz-i Zati",
  },
  {
    id: 52006,
    parentId: 51127,
    label: "Temyiz-i Nisbi",
  },

  {
    id: 52007,
    parentId: 52006,
    label: "Muhavvel",
  },

  {
    id: 52020,
    parentId: 52007,
    label: "Fâilden",
  },
  {
    id: 52021,
    parentId: 52007,
    label: "Mef'ûlden",
  },
  {
    id: 52022,
    parentId: 52007,
    label: "Mübtedâdan",
  },

  {
    id: 52011,
    parentId: 52006,
    label: "Ğayrı Muhavvel",
  },

  {
    id: 52012,
    parentId: 5112,
    label: "Münâdâ",
  },

  {
    id: 52023,
    parentId: 52012,
    label: "Müfred-i Alem",
  },
  {
    id: 52024,
    parentId: 52012,
    label: "Nekire-i Maksude",
  },
  {
    id: 52025,
    parentId: 52012,
    label: "Nekire-i Gayrı Maksude",
  },
  {
    id: 52026,
    parentId: 52012,
    label: "Mudaf",
  },
  {
    id: 52027,
    parentId: 52012,
    label: "Şibh-i Mudaf",
  },

  {
    id: 51129,
    parentId: 5112,
    label: "Müstesnâ",
  },

  {
    id: 511291,
    parentId: 5112,
    label: 'إسم "لا" النافية للجنس',
  },

  {
    id: 52028,
    parentId: 511291,
    label: "Müfred",
  },
  {
    id: 52029,
    parentId: 511291,
    label: "Nekireye İdafe Edilen",
  },
  {
    id: 52030,
    parentId: 511291,
    label: "Şibh-i Mudaf",
  },

  {
    id: 511292,
    parentId: 5112,
    label: 'خبر "كان" وأخواتها',
  },
  {
    id: 511293,
    parentId: 5112,
    label: 'إسم "إن" وأخواتها',
  },
  {
    id: 511294,
    parentId: 5112,
    label: "Tabiiler",
  },

  {
    id: 52031,
    parentId: 511294,
    label: "Sıfat",
  },
  {
    id: 52032,
    parentId: 511294,
    label: "Tekid",
  },
  {
    id: 52033,
    parentId: 511294,
    label: "Atıf",
  },
  {
    id: 52034,
    parentId: 511294,
    label: "Bedel",
  },

  {
    id: 51130,
    parentId: 5113,
    label: "Car-ı Mecrur",
  },
  {
    id: 51131,
    parentId: 5113,
    label: "Muzâfu'n-İleyh",
  },

  {
    id: 52035,
    parentId: 51131,
    label: "من",
  },
  {
    id: 52036,
    parentId: 51131,
    label: "في",
  },
  {
    id: 52037,
    parentId: 51131,
    label: "إلى",
  },

  {
    id: 51132,
    parentId: 5113,
    label: "Tabiiler",
  },
  {
    id: 52038,
    parentId: 51132,
    label: "Sıfat",
  },
  {
    id: 52039,
    parentId: 51132,
    label: "Tekid",
  },
  {
    id: 52040,
    parentId: 51132,
    label: "Atıf",
  },
  {
    id: 52041,
    parentId: 51132,
    label: "Bedel",
  },
  {
    id: 411,
    parentId: 1,
    label: "Esma-i Hamse",
  },

  {
    id: 4111,
    parentId: 411,
    label: "Merfu",
  },
  {
    id: 4112,
    parentId: 411,
    label: "Mensub",
  },
  {
    id: 4113,
    parentId: 411,
    label: "Mecrur",
  },
  {
    id: 41111,
    parentId: 4111,
    label: "Fâil",
  },
  {
    id: 41011,
    parentId: 41111,
    label: "Zahir",
  },
  {
    id: 41012,
    parentId: 41111,
    label: "Zamir",
  },
  {
    id: 41112,
    parentId: 4111,
    label: "Nâib-i Fâil",
  },
  {
    id: 41311,
    parentId: 41112,
    label: "Zahir",
  },
  {
    id: 41312,
    parentId: 41112,
    label: "Zamir",
  },
  {
    id: 41113,
    parentId: 4111,
    label: "Mübtedâ",
  },
  {
    id: 41911,
    parentId: 41113,
    label: "Zahir",
  },
  {
    id: 41912,
    parentId: 41113,
    label: "Zamir",
  },
  {
    id: 41114,
    parentId: 4111,
    label: "Haber",
  },
  {
    id: 41091,
    parentId: 41114,
    label: "Müfred",
  },
  {
    id: 41092,
    parentId: 41114,
    label: "Müfred Olmayan",
  },
  {
    id: 41093,
    parentId: 41092,
    label: "Cümle",
  },
  {
    id: 42093,
    parentId: 41093,
    label: "İsim Cümlesi",
  },
  {
    id: 42094,
    parentId: 41093,
    label: "Fiil Cümlesi",
  },
  {
    id: 41094,
    parentId: 41092,
    label: "Şibh-i Cümle",
  },
  {
    id: 42095,
    parentId: 41094,
    label: "Zarf",
  },
  {
    id: 42096,
    parentId: 41094,
    label: "Car-ı Mecrur",
  },
  {
    id: 41115,
    parentId: 4111,
    label: 'إسم "كان" وأخواتها',
  },
  {
    id: 41116,
    parentId: 4111,
    label: 'خبر "إن" وأخواتها',
  },
  {
    id: 41117,
    parentId: 4111,
    label: "Tabiiler",
  },
  {
    id: 43200,
    parentId: 41117,
    label: "Sıfat",
  },
  {
    id: 43201,
    parentId: 41117,
    label: "Tekid",
  },
  {
    id: 43202,
    parentId: 41117,
    label: "Atıf",
  },
  {
    id: 43203,
    parentId: 41117,
    label: "Bedel",
  },

  {
    id: 41121,
    parentId: 4112,
    label: "Mef'ûlu Bihi",
  },

  {
    id: 42000,
    parentId: 41121,
    label: "Zahir",
  },
  {
    id: 42001,
    parentId: 41121,
    label: "Zamir",
  },

  {
    id: 41122,
    parentId: 4112,
    label: "Mef'ûlu Fihi",
  },
  {
    id: 41123,
    parentId: 4112,
    label: "Mef'ûlu Lehu",
  },
  {
    id: 41124,
    parentId: 4112,
    label: "Mef'ûlu Me'ahu",
  },
  {
    id: 41125,
    parentId: 4112,
    label: "Mef'ûlu Mutlak",
  },

  {
    id: 42002,
    parentId: 41125,
    label: "Tekidi",
  },
  {
    id: 42003,
    parentId: 41125,
    label: "Nev'i",
  },
  {
    id: 42004,
    parentId: 41125,
    label: "Adedi",
  },

  {
    id: 41126,
    parentId: 4112,
    label: "Hal",
  },
  {
    id: 41127,
    parentId: 4112,
    label: "Temyiz",
  },

  {
    id: 42005,
    parentId: 41127,
    label: "Temyiz-i Zati",
  },
  {
    id: 42006,
    parentId: 41127,
    label: "Temyiz-i Nisbi",
  },

  {
    id: 42007,
    parentId: 42006,
    label: "Muhavvel",
  },

  {
    id: 42020,
    parentId: 42007,
    label: "Fâilden",
  },
  {
    id: 42021,
    parentId: 42007,
    label: "Mef'ûlden",
  },
  {
    id: 42022,
    parentId: 42007,
    label: "Mübtedâdan",
  },

  {
    id: 42011,
    parentId: 42006,
    label: "Ğayrı Muhavvel",
  },

  {
    id: 42012,
    parentId: 4112,
    label: "Münâdâ",
  },

  {
    id: 42023,
    parentId: 42012,
    label: "Müfred-i Alem",
  },
  {
    id: 42024,
    parentId: 42012,
    label: "Nekire-i Maksude",
  },
  {
    id: 42025,
    parentId: 42012,
    label: "Nekire-i Gayrı Maksude",
  },
  {
    id: 42026,
    parentId: 42012,
    label: "Mudaf",
  },
  {
    id: 42027,
    parentId: 42012,
    label: "Şibh-i Mudaf",
  },

  {
    id: 41129,
    parentId: 4112,
    label: "Müstesnâ",
  },

  {
    id: 411291,
    parentId: 4112,
    label: 'إسم "لا" النافية للجنس',
  },

  {
    id: 42028,
    parentId: 411291,
    label: "Müfred",
  },
  {
    id: 42029,
    parentId: 411291,
    label: "Nekireye İdafe Edilen",
  },
  {
    id: 42030,
    parentId: 411291,
    label: "Şibh-i Mudaf",
  },

  {
    id: 411292,
    parentId: 4112,
    label: 'خبر "كان" وأخواتها',
  },
  {
    id: 411293,
    parentId: 4112,
    label: 'إسم "إن" وأخواتها',
  },
  {
    id: 411294,
    parentId: 4112,
    label: "Tabiiler",
  },

  {
    id: 42031,
    parentId: 411294,
    label: "Sıfat",
  },
  {
    id: 42032,
    parentId: 411294,
    label: "Tekid",
  },
  {
    id: 42033,
    parentId: 411294,
    label: "Atıf",
  },
  {
    id: 42034,
    parentId: 411294,
    label: "Bedel",
  },

  {
    id: 41130,
    parentId: 4113,
    label: "Car-ı Mecrur",
  },
  {
    id: 41131,
    parentId: 4113,
    label: "Muzâfu'n-İleyh",
  },

  {
    id: 42035,
    parentId: 41131,
    label: "من",
  },
  {
    id: 42036,
    parentId: 41131,
    label: "في",
  },
  {
    id: 42037,
    parentId: 41131,
    label: "إلى",
  },

  {
    id: 41132,
    parentId: 4113,
    label: "Tabiiler",
  },
  {
    id: 42038,
    parentId: 41132,
    label: "Sıfat",
  },
  {
    id: 42039,
    parentId: 41132,
    label: "Tekid",
  },
  {
    id: 42040,
    parentId: 41132,
    label: "Atıf",
  },
  {
    id: 42041,
    parentId: 41132,
    label: "Bedel",
  },
];
export function IrabSemasi() {
  return (
    <div
      className="container-fluid mainContent p-4"
      style={{ backgroundColor: "#f9edd1" }}
    >
      <ReactTree nodes={data} />
    </div>
  );
}
