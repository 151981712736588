import { useState } from "react";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { SigaBulucu } from "./emsile/SigaBulucu";
import { Muttaride } from "./emsile/Muttaride";
import { Muhtelife } from "./emsile/Muhtelife";
import { Terimler as EmsileTerimler } from "./emsile/Terimler";
import { BabBulmaca } from "./bina/BabBulucu";
import { Bablar } from "./bina/Bablar";
import { AksamiSeba } from "./bina/AksamiSeba";
import { AksamiSemani } from "./bina/AksamiSemani";
import { Terimler as BinaTerimler } from "./bina/Terimler";
import { IzziIbare } from "./izzi/IzziIbare";
import { KelimeKartlari } from "./kelime/KelimeKartlari";
import { KelimeBulmaca } from "./kelime/kelimeBulmaca";
import { AnaSayfa } from "./components/AnaSayfa";
import { Hakkimizda } from "./components/Hakkimizda";
import { Video } from "./components/Video";
import { IbareOkuyucu } from "./components/IbareOkuyucu";
import { IzziCekimler } from "./izzi/Cekimler";
import { IzziBulmaca } from "./izzi/Bulmaca";
import { IrabSemasi } from "./tuhfe/IrabSemasi";

function App() {
  const [yanMenuGorunsun, setYanMenuGorunsun] = useState(false);

  const yanMenuAcDugmesineBasildi = () => {
    setYanMenuGorunsun(true);
  };

  const yanMenuKapatDugmesineBasildi = () => {
    setYanMenuGorunsun(false);
  };

  return (
    <div>
      <Router>
        <div className="container-fluid header py-2">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-2">
                <img
                  src="/assets/images/bars-solid.svg"
                  className="img-fluid sideMenuBars"
                  onClick={yanMenuAcDugmesineBasildi}
                  alt="Menu"
                />
              </div>
              <div className="col-5 text-end">
                <a href="/">
                  <img
                    src="/assets/images/online-gramer-logo.png"
                    className="img-fluid headerLogo"
                    alt="medrese.online"
                  />
                </a>
              </div>
            </div>

            <div className={`sideMenu ${yanMenuGorunsun && "active"}`}>
              <div
                className="sideMenuClose"
                onClick={yanMenuKapatDugmesineBasildi}
              >
                <img src="/assets/images/xmark-solid.svg" alt="Kapat" />
              </div>
              <div className="sideMenuContent">
                <div>
                  <h3>Emsile</h3>
                  <ul>
                    <li>
                      <Link
                        to="/emsile/muhtelife"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Muhtelife
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/emsile/muttaride"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Muttaride
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/emsile/terimler"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Terimler
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/emsile/sigaBulucu"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Bulmaca
                      </Link>
                    </li>
                  </ul>
                  <h3>Bina</h3>
                  <ul>
                    <li>
                      <Link
                        to="/bina/bablar"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Bablar
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/bina/aksami_semani"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Aksam-ı Semaniye
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/bina/aksami_seba"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Aksam-ı Seb'a
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/bina/terimler"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Terimler
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/bina/babBulmaca"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Bulmaca
                      </Link>
                    </li>
                  </ul>
                  <h3>İzzi</h3>
                  <ul>
                    <li>
                      <Link
                        to="/izzi/cekim"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Çekimler
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/izzi/ibare"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        İbare
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/izzi/bulmaca"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Bulmaca
                      </Link>
                    </li>
                  </ul>
                  <h3>Tuhfetü's Seniyye</h3>
                  <ul>
                    <li>
                      <Link
                        to="/tuhfe/irabSemasi"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        İ'rab Şeması
                      </Link>
                    </li>
                  </ul>
                  <h3>Kelimeler</h3>
                  <ul>
                    <li>
                      <Link
                        to="/kelime/kartlar"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Kartlar
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/kelime/bulmaca"
                        onClick={yanMenuKapatDugmesineBasildi}
                      >
                        Bulmaca
                      </Link>
                    </li>
                  </ul>
                  <Link to="/video" onClick={yanMenuKapatDugmesineBasildi}>
                    Videolar (Yeni)
                  </Link>
                  <Link to="/hakkimizda" onClick={yanMenuKapatDugmesineBasildi}>
                    Hakkımızda
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Routes>
          <Route exact path="/" element={<AnaSayfa />} />
          <Route exact path="/emsile/muhtelife" element={<Muhtelife />} />
          <Route exact path="/emsile/muttaride" element={<Muttaride />} />
          <Route exact path="/emsile/sigaBulucu" element={<SigaBulucu />} />
          <Route exact path="/emsile/terimler" element={<EmsileTerimler />} />
          <Route exact path="/bina/bablar" element={<Bablar />} />
          <Route exact path="/bina/aksami_semani" element={<AksamiSemani />} />
          <Route exact path="/bina/aksami_seba" element={<AksamiSeba />} />
          <Route exact path="/bina/babBulmaca" element={<BabBulmaca />} />
          <Route exact path="/izzi/ibare" element={<IzziIbare />} />
          <Route exact path="/izzi/cekim" element={<IzziCekimler />} />
          <Route exact path="/izzi/bulmaca" element={<IzziBulmaca />} />
          <Route exact path="/tuhfe/irabSemasi" element={<IrabSemasi />} />
          <Route exact path="/bina/terimler" element={<BinaTerimler />} />
          <Route exact path="/kelime/kartlar" element={<KelimeKartlari />} />
          <Route exact path="/kelime/bulmaca" element={<KelimeBulmaca />} />
          <Route exact path="/hakkimizda" element={<Hakkimizda />} />
          <Route exact path="/video" element={<Video />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
