import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Toast from "react-bootstrap/Toast";

export function ButtonWithToast(props) {
  const [showToast, setShowToast] = useState(false);

  const toggleToast = () => setShowToast(!showToast);

  useEffect(() => {
    setShowToast(false);
  }, [props.kelime]);

  return (
    <div className="text-black">
      <Button onClick={toggleToast} className="mb-2">
        {props.buttonText}
      </Button>
      <Toast show={showToast} onClose={toggleToast} animation={false}>
        <Toast.Body>{props.toastMessage}</Toast.Body>
      </Toast>
    </div>
  );
}
