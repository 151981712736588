import React from "react";

export function AnaSayfa() {
  return (
    <div className="container-fluid mainContent py-5 text-white">
      <div className="container">
        <h1 className="display-6 text-lighter">Emsile</h1>
        <div className="row">
          <div className="col-3 col-md-2 col-lg-1">
            <a href="/emsile/muhtelife" className="homeIcon">
              <img src="/assets/images/book-open-solid.svg" alt="Muhtelife" />
              Muhtelife
            </a>
          </div>
          <div className="col-3 col-md-2 col-lg-1">
            <a href="/emsile/muttaride" className="homeIcon">
              <img src="/assets/images/book-solid.svg" alt="Muttaride" />
              Muttaride
            </a>
          </div>
          <div className="col-3 col-md-2 col-lg-1">
            <a href="/emsile/terimler" className="homeIcon">
              <img src="/assets/images/leanpub.svg" alt="Terimler" />
              Terimler
            </a>
          </div>
          <div className="col-3 col-md-2 col-lg-1">
            <a href="/emsile/sigaBulucu" className="homeIcon">
              <img
                src="/assets/images/chalkboard-user-solid.svg"
                alt="Bulmaca"
              />
              Bulmaca
            </a>
          </div>
        </div>

        <hr />

        <h1 className="display-6 text-lighter">Bina</h1>
        <div className="row">
          <div className="col-3 col-md-2 col-lg-1">
            <a href="/bina/bablar" className="homeIcon">
              <img src="/assets/images/book-open-solid.svg" alt="Bablar" />
              Bablar
            </a>
          </div>
          <div className="col-3 col-md-2 col-lg-1">
            <a href="/bina/aksami_semani" className="homeIcon">
              <img src="/assets/images/book-solid.svg" alt="Aksam-ı Semaniye" />
              Aksam-ı Semaniye
            </a>
          </div>
          <div className="col-3 col-md-2 col-lg-1">
            <a href="/bina/aksami_seba" className="homeIcon">
              <img src="/assets/images/book-solid.svg" alt="Aksam-ı Seba" />
              Aksam-ı Seba
            </a>
          </div>
          <div className="col-3 col-md-2 col-lg-1">
            <a href="/bina/terimler" className="homeIcon">
              <img src="/assets/images/leanpub.svg" alt="Terimler" />
              Terimler
            </a>
          </div>
          <div className="col-3 col-md-2 col-lg-1">
            <a href="/bina/babBulmaca" className="homeIcon">
              <img
                src="/assets/images/chalkboard-user-solid.svg"
                alt="Bulmaca"
              />
              Bulmaca
            </a>
          </div>
        </div>

        <hr />

        <h1 className="display-6 text-lighter">Kelimeler</h1>
        <div className="row">
          <div className="col-3 col-md-2 col-lg-1">
            <a href="/kelime/kartlar" className="homeIcon">
              <img src="/assets/images/graduation-cap.svg" alt="Kartlar" />
              Kartlar
            </a>
          </div>
          <div className="col-3 col-md-2 col-lg-1">
            <a href="/kelime/bulmaca" className="homeIcon">
              <img
                src="/assets/images/chalkboard-user-solid.svg"
                alt="Bulmaca"
              />
              Bulmaca
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
