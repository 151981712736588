import React, { useEffect, useState } from "react";
import axios from "axios";

export function Muhtelife() {
  const [kelimeler, setKelimeler] = useState([]);
  const [seciliKelimeId, setSeciliKelimeId] = useState("");
  const [sigaTabloVerisi, setSigaTabloVerisi] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1.0/kelime_listesi")
      .then((response) => {
        let kelimeDat = response.data.data.map((item) => {
          return {
            label: item.mazi + " - " + item.muzari + " (" + item.mana + ")",
            value: item.id,
          };
        });
        setKelimeler(kelimeDat);
        setSeciliKelimeId(kelimeDat[0].value);
        tabloVerisiGetir(kelimeDat[0].value);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function kelimeSecilince(secilenKelime) {
    setSeciliKelimeId(secilenKelime);
    tabloVerisiGetir(secilenKelime);
  }

  async function tabloVerisiGetir(secilenKelime) {
    let request_data = { kelime_id: parseInt(secilenKelime) };
    axios
      .post("/api/v1.0/emsile/muhtelife", request_data)
      .then((response) => {
        let dat = [
          response.data.data.slice(0, 6),
          response.data.data.slice(6, 12),
          response.data.data.slice(12, 18),
          response.data.data.slice(18, 23),
        ];
        setSigaTabloVerisi(dat);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="container-fluid mainContent py-5 text-white text-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <h1 className="display-6 mb-3">Emsile-i Muhtelife</h1>

            <select
              className="form-control select"
              value={seciliKelimeId}
              onChange={(e) => kelimeSecilince(e.target.value)}
            >
              {kelimeler.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {Array.isArray(sigaTabloVerisi) && sigaTabloVerisi.length > 0 && (
          <div className="container">
            {sigaTabloVerisi.map((item, row_index) => (
              <div
                key={row_index}
                className={`row ${row_index === 0 && "mt-5"}`}
              >
                <div className="col-4 col-lg-2 mb-4 order-2 order-lg-5">
                  <div className="wordCard">
                    <div className="AR">{item[0].kelime}</div>
                    <div className="TR">
                      {/* {row_index * 6 + 1 + ". " + item[0].siga} */}
                      {item[0].siga}
                    </div>
                  </div>
                </div>
                <div className="col-4 col-lg-2 mb-4 order-1 order-lg-4">
                  <div className="wordCard">
                    <div className="AR">{item[1].kelime}</div>
                    <div className="TR">
                      {/* {row_index * 6 + 2 + ". " + item[1].siga} */}
                      {item[1].siga}
                    </div>
                  </div>
                </div>
                <div className="col-4 col-lg-2 mb-4 order-0 order-lg-3">
                  <div className="wordCard">
                    <div className="AR">{item[2].kelime}</div>
                    <div className="TR">
                      {/* {row_index * 6 + 3 + ". " + item[2].siga} */}
                      {item[2].siga}
                    </div>
                  </div>
                </div>
                <div className="col-4 col-lg-2 mb-4 order-5 order-lg-2">
                  <div className="wordCard">
                    <div className="AR">{item[3].kelime}</div>
                    <div className="TR">
                      {/* {row_index * 6 + 4 + ". " + item[3].siga} */}
                      {item[3].siga}
                    </div>
                  </div>
                </div>
                <div className="col-4 col-lg-2 mb-4 order-4 order-lg-1">
                  <div className="wordCard">
                    <div className="AR">{item[4].kelime}</div>
                    <div className="TR">
                      {/* {row_index * 6 + 5 + ". " + item[4].siga} */}
                      {item[4].siga}
                    </div>
                  </div>
                </div>
                {item.length > 5 && (
                  <div className="col-4 col-lg-2 mb-4 order-3 order-lg-0">
                    <div className="wordCard">
                      <div className="AR">{item[5].kelime}</div>
                      <div className="TR">
                        {/* {row_index * 6 + 6 + ". " + item[5].siga} */}
                        {item[5].siga}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
