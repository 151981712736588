import React from "react";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";

export function FiilKarti(props) {
  let ilal_listesi = props.fiil;
  let fiilde_ilal_var = props.fiil.length > 1;

  return (
    <div className={props.col}>
      <OverlayTrigger
        rootClose={true}
        trigger={fiilde_ilal_var && ["click"]}
        key="bottom"
        placement="bottom"
        overlay={
          <Popover>
            <Popover.Header as="h3">İ'lal Listesi</Popover.Header>
            <Popover.Body style={{ direction: "rtl" }}>
              <hr className="rounded" />
              {ilal_listesi.map((ilal_detayi, index) => (
                <div key={index}>
                  <strong
                    style={{
                      fontSize: "30px",
                      color: "green",
                    }}
                  >
                    {ilal_detayi.fiil}
                  </strong>
                  {index == 0 ? "     (Fiilin Aslı)" : ""}
                  {index == ilal_listesi.length - 1
                    ? "     (Fiilin Son Hali)"
                    : ""}
                  <div
                    style={{
                      direction: "ltr",
                      marginLeft: "20px",
                      color: "gray",
                    }}
                  >
                    {ilal_detayi.ilal}
                  </div>
                  <hr className="rounded" />
                </div>
              ))}
            </Popover.Body>
          </Popover>
        }
      >
        <div className="wordCard">
          <div className="AR">
            {props.ilal ? props.fiil.slice(-1)[0].fiil : props.fiil}
            {fiilde_ilal_var && (
              <Badge
                style={{
                  fontSize: "9px",
                  position: "absolute",
                  marginLeft: "3px",
                }}
                pill
                bg="warning"
                text="dark"
              >
                !
              </Badge>
            )}
          </div>
          <div className="TR">{props.failSayisi}</div>
        </div>
      </OverlayTrigger>
    </div>
  );
}
