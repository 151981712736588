import React, { useState } from "react";
import axios from "axios";
import { FiilSecimi } from "./FiilSecimi";
import { MuttarideTablosu } from "../components/MuttarideTablosu";

export function IzziCekimler() {
  const [sigaTabloVerisiMazi, setSigaTabloVerisiMazi] = useState([]);
  const [sigaTabloVerisiMaziMutekellim, setSigaTabloVerisiMaziMutekellim] =
    useState([]);
  const [sigaTabloVerisiMuzari, setSigaTabloVerisiMuzari] = useState([]);
  const [sigaTabloVerisiMuzariMutekellim, setSigaTabloVerisiMuzariMutekellim] =
    useState([]);

  async function kelimeSecildi(kelime, harf_sayisi, yapi, bab) {
    let request_data = {
      fiil: kelime,
      harf_sayisi: harf_sayisi,
      yapi: yapi,
      bab: bab,
    };
    const result = await axios.post(
      "/api/v1.0/sarf/bina/bab/kelime",
      request_data
    );
    console.log(result);
    setSigaTabloVerisiMazi(result.data.mazi_ilal);
    setSigaTabloVerisiMuzari(result.data.muzari_ilal);
    setSigaTabloVerisiMaziMutekellim(result.data.mazi_mutekellim);
    setSigaTabloVerisiMuzariMutekellim(result.data.muzari_mutekellim);
  }

  return (
    <div className="container-fluid mainContent py-5 text-white text-center">
      <FiilSecimi kelimeSecildi={kelimeSecildi}></FiilSecimi>
      <div
        className="row"
        style={{
          direction: "rtl",
        }}
      >
        <div className="col-md-6">
          <MuttarideTablosu
            sigaIsmi="Mazi"
            muttarideTablosuVerisi={sigaTabloVerisiMazi}
            mutekellimVerisi={sigaTabloVerisiMaziMutekellim}
          ></MuttarideTablosu>
        </div>
        <div className="col-md-6">
          <MuttarideTablosu
            sigaIsmi="Muzari"
            muttarideTablosuVerisi={sigaTabloVerisiMuzari}
            mutekellimVerisi={sigaTabloVerisiMuzariMutekellim}
          ></MuttarideTablosu>
        </div>
      </div>
    </div>
  );
}
