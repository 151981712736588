import React from "react";

export function AksamiSeba() {
  return (
    <div className="container-fluid mainContent py-5 text-white">
      <div className="container">
        <h1 className="display-6 mb-3 text-center">Aksam-ı Seb'â</h1>

        <div className="row mt-4 justify-content-center">
          <h1 className="display-6">Sahih</h1>
          <hr />
          <div className="col-9">
            <div className="row wordWord">
              <div className="col-12 TR">
                Bir fiilin; asıl harfleri içinde illet harfi (ا - ي - و), hemze
                ve tad'if'in olmamasıdır.
              </div>
              <div className="col-6 text-center AR">شَكَرَ</div>
              <div className="col-6 text-center AR">حَصُنَ</div>
            </div>
          </div>
        </div>

        <div className="row mt-4 justify-content-center">
          <h1 className="display-6">Misâl</h1>
          <hr />
          <div className="col-9">
            <div className="row wordWord">
              <div className="col-12 TR">
                Bir fiilin; sadece fâu'l fiilinin illet harflerinden biri
                olmasıdır.
              </div>
              <div className="col-6 text-center AR">وَعَدَ</div>
              <div className="col-6 text-center AR">يَسَرَ</div>
            </div>
          </div>
        </div>

        <div className="row mt-4 justify-content-center">
          <h1 className="display-6">Ecvef</h1>
          <hr />
          <div className="col-9">
            <div className="row wordWord">
              <div className="col-12 TR">
                Bir fiilin; sadece aynu'l fiilinin illet harflerinden biri
                olmasıdır.
              </div>
              <div className="col-6 text-center AR">بَامَ</div>
              <div className="col-6 text-center AR">قَالَ</div>
            </div>
          </div>
        </div>

        <div className="row mt-4 justify-content-center">
          <h1 className="display-6">Nakıs</h1>
          <hr />
          <div className="col-9">
            <div className="row wordWord">
              <div className="col-12 TR">
                Bir fiilin; sadece lâmu'l fiilinin illet harflerinden biri
                olmasıdır.
              </div>
              <div className="col-6 text-center AR">رَمَى</div>
              <div className="col-6 text-center AR">غَزَا</div>
            </div>
          </div>
        </div>

        <div className="row mt-4 justify-content-center">
          <h1 className="display-6">Lefif</h1>
          <hr />
          <div className="col-9">
            <div className="row wordWord">
              <div className="col-12 TR">
                Bir fiilin; asıl harflerinden iki tanesi illet harflerinden biri
                olursa bu fiile lefif fiil denir.
              </div>
              <br />
              Lefif iki kısma ayrılır;
              <br />
              <b>a) Lefif-i Makrun:</b> Aynu'l fiil ve lâmu'l fiil, illet
              harflerinden biri olursa buna lefif-i mâkrun denir.
              <div className="col-6 text-center AR">طَوَى</div>
              <div className="col-6 text-center AR">َقَوِى</div>
              <b>b) Lefif-i Mefruk:</b> Fâu'l fiil ve lâmu'l fiil, illet
              harflerinden biri olursa buna lefif-i mefruk denir.
              <div className="col-6 text-center AR">يَقِي</div>
              <div className="col-6 text-center AR">وَقَى</div>
            </div>
          </div>
        </div>

        <div className="row mt-4 justify-content-center">
          <h1 className="display-6">Muzâf</h1>
          <hr />
          <div className="col-9">
            <div className="row wordWord">
              <div className="col-12 TR">
                Sülasi bir fiilin; aynu'l fiil ve lâmu'l fiilinin aynı cinsten
                olmasıdır. Rubâi bir fiilin; fâu'l fiil ve lâmu'l evvelin, aynul
                fiil ve lâmu's saninin aynı cinsten olmasıdır.
              </div>
              <div className="col-6 text-center AR">زَلْزَلَ</div>
              <div className="col-6 text-center AR">مَدَّ</div>
            </div>
          </div>
        </div>

        <div className="row mt-4 justify-content-center">
          <h1 className="display-6">Mehmuz</h1>
          <hr />
          <div className="col-9">
            <div className="row wordWord">
              <div className="col-12 TR">
                Bir fiilin; asıl harflerinden birinin hemze olmasıdır. Hemzenin
                konumuna göre isimlendirilir (Mehmuz-ul Fâ, Mehmuz-ul Ayn,
                Mehmuz-ul Lâm).
              </div>
              <div className="col-4 text-center AR">قَرَأَ</div>
              <div className="col-4 text-center AR">سَأَلَ</div>
              <div className="col-4 text-center AR">أَخَذَ</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
