import React from "react";
import { FiilKarti } from "./FiilKarti";

export function MuttarideTablosu(props) {
  let sigaIsmi = props.sigaIsmi;
  let muttarideVerisi = props.muttarideTablosuVerisi;
  let mutekellimVerisi = props.mutekellimVerisi;

  return (
    <div className="wordSet">
      <div className="label">{sigaIsmi}</div>
      {muttarideVerisi.map((item, index) => (
        <div
          key={index}
          className="wordSet"
          style={{
            direction: "ltr",
          }}
        >
          <div className={muttarideVerisi[index].aciklama && "label"}>
            {muttarideVerisi[index].aciklama}
          </div>
          <div className="row">
            <FiilKarti
              ilal={true}
              fiil={muttarideVerisi[index].cem}
              failSayisi="Cem"
              col="col-4"
            ></FiilKarti>
            <FiilKarti
              ilal={true}
              fiil={muttarideVerisi[index].tesniye}
              failSayisi="Tesniye"
              col="col-4"
            ></FiilKarti>
            <FiilKarti
              ilal={true}
              fiil={muttarideVerisi[index].mufred}
              failSayisi="Müfred"
              col="col-4"
            ></FiilKarti>
          </div>
        </div>
      ))}

      {/* {Array.isArray(mutekellimVerisi) && mutekellimVerisi.length > 0 && (
        <div className="wordSet">
          <div className="label">{mutekellimVerisi[0].aciklama}</div>
          <div className="row">
            <FiilKarti
              ilal={false}
              fiil={mutekellimVerisi[0].vahdehu}
              failSayisi="Vahdehu"
              col="col-6"
            ></FiilKarti>
            <FiilKarti
              ilal={false}
              fiil={mutekellimVerisi[0].meal_gayr}
              failSayisi="Meal Gayr"
              col="col-6"
            ></FiilKarti>
          </div>
        </div>
      )} */}
    </div>
  );
}
