import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { CokluSecim } from "../components/CokluSecim";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ButtonWithToast } from "../components/ButtonWithToast";
import { ButtonWithToastList } from "../components/ButtonWithToastList";

export function IzziBulmaca() {
  const [bablar, setBablar] = useState([]);
  const [seciliBablar, setSeciliBablar] = useState([]);

  const [ilalModlari, setIlalModlari] = useState([]);
  const [seciliIlalModu, setSeciliIlalModu] = useState(1);

  const [seciliBulmacaYonu, setSeciliBulmacaYonu] = useState(1);
  const bulmacaYonleri = [
    { label: "Fiilin Aslını Bul", value: 1 },
    { label: "Fiile İlal Uygula", value: 2 },
  ];

  const [sorulacakKelimeDetayi, setSorulacakKelimeDetayi] = useState({});
  const [kelime, setKelime] = useState("");

  const [babSecimDialogGorunsun, setBabSecimDialogGorunsun] = useState(false);

  useEffect(() => {
    axios
      .get("/api/v1.0/izzi/bulmaca/bablar")
      .then((response) => {
        let dat = response.data.data.map((item) => {
          return { name: item.isim, code: item.id };
        });
        setBablar(dat);
        let seciliBablarDat = dat.map((item) => {
          return item.code;
        });
        setSeciliBablar(seciliBablarDat);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/api/v1.0/izzi/bulmaca/ilalModlari")
      .then((response) => {
        let dat = response.data.data.map((item) => {
          console.log(item);
          return { label: item.label, value: item.value };
        });

        setIlalModlari(dat);
        setSeciliIlalModu(dat[0].value);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function onSubmitYeniKelime() {
    let request_data = {
      ilal_modu: parseInt(seciliIlalModu),
      secili_bablar: seciliBablar,
    };
    const result = await axios.post(
      "/api/v1.0/izzi/bulmaca/kelime",
      request_data
    );
    console.log(result["data"]);
    setSorulacakKelimeDetayi(result.data);
    let sorulacak_kelime_ilal_listesi = result.data.ilal_listesi;
    if (seciliBulmacaYonu === 1) {
      // aslini bul
      setKelime(
        sorulacak_kelime_ilal_listesi[sorulacak_kelime_ilal_listesi.length - 1]
          .fiil
      );
    } else {
      // ilal uygula
      setKelime(sorulacak_kelime_ilal_listesi[0].fiil);
    }
  }

  async function babSecDugmesineBasildi() {
    setBabSecimDialogGorunsun(true);
  }

  async function babSecimiGonderDugmesineBasildi(secilenler) {
    setBabSecimDialogGorunsun(false);
    setSeciliBablar(secilenler);
  }

  async function babSecKapatBasildi() {
    setBabSecimDialogGorunsun(false);
  }

  async function ilalModuSecilince(ilal_modu) {
    setSeciliIlalModu(ilal_modu);
    onSubmitYeniKelime();
  }

  async function bulmacaYonuSecilince(bulmaca_yonu) {
    setSeciliBulmacaYonu(bulmaca_yonu);
    onSubmitYeniKelime();
  }

  return (
    <div className="container-fluid mainContent py-5 text-white">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-6">
            <h1 className="display-6 mb-3">
              Izzi Bulmaca{" "}
              <Button variant="primary" onClick={babSecDugmesineBasildi}>
                Bab Seç
              </Button>
            </h1>
            <hr />
            <Row>
              <Col md={4} className="mb-1">
                <select
                  className="form-control select"
                  value={seciliIlalModu}
                  onChange={(e) => ilalModuSecilince(e.target.value)}
                >
                  {ilalModlari.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={4} className="mb-1">
                <select
                  className="form-control select"
                  value={seciliBulmacaYonu}
                  onChange={(e) => bulmacaYonuSecilince(e.target.value)}
                >
                  {bulmacaYonleri.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={4} className="mb-1">
                <button
                  className="btn btn-outline-light"
                  type="button"
                  onClick={onSubmitYeniKelime}
                >
                  Yeni Kelime
                </button>
              </Col>
            </Row>
            {kelime && <hr />}

            <div className="text-center my-3 text-warning">
              <h2 className="display-1 text-center">{kelime}</h2>
            </div>
            <hr />

            {kelime && (
              <div>
                <Row>
                  <Col md={4} className="mb-1">
                    <ButtonWithToast
                      buttonText="Siga"
                      toastMessage={sorulacakKelimeDetayi.siga}
                      kelime={kelime}
                    ></ButtonWithToast>
                  </Col>
                  <Col md={4} className="mb-1">
                    <ButtonWithToast
                      buttonText="Fâil Sayısı"
                      toastMessage={sorulacakKelimeDetayi.sahis_tipi}
                      kelime={kelime}
                    ></ButtonWithToast>
                  </Col>
                  <Col md={4} className="mb-1">
                    <ButtonWithToast
                      buttonText="Fâil Tipi"
                      toastMessage={sorulacakKelimeDetayi.gaib_muhatab}
                      kelime={kelime}
                    ></ButtonWithToast>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={8} className="mb-1">
                    <ButtonWithToast
                      buttonText="Bab"
                      toastMessage={
                        sorulacakKelimeDetayi.bab[0] +
                        ", " +
                        sorulacakKelimeDetayi.bab[1] +
                        ", " +
                        sorulacakKelimeDetayi.bab[3]
                      }
                      kelime={kelime}
                    ></ButtonWithToast>
                  </Col>
                  <Col md={4} className="mb-1">
                    <ButtonWithToast
                      buttonText="Aksam"
                      toastMessage={sorulacakKelimeDetayi.bab[2]}
                      kelime={kelime}
                    ></ButtonWithToast>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="mb-1">
                    <ButtonWithToastList
                      buttonText="İ'lal Listesi"
                      toastList={sorulacakKelimeDetayi.ilal_listesi}
                      kelime={kelime}
                    ></ButtonWithToastList>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>

        <CokluSecim
          baslik="Bab Seçiniz"
          gorunur={babSecimDialogGorunsun}
          secenekler={bablar}
          seciliSecenekler={seciliBablar}
          tamamaBasildi={babSecimiGonderDugmesineBasildi}
          kapataBasildi={babSecKapatBasildi}
          satiraYay={false}
        ></CokluSecim>
      </div>
    </div>
  );
}
