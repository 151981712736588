import React, { useEffect, useState } from "react";
import axios from "axios";

export function TerimTablosu(props) {
  const [terimler, setTerimler] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1.0/terimler", { params: { ders: props.ders } })
      .then((response) => {
        setTerimler(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="container-fluid mainContent py-5 text-white text-center">
      <h1 className="display-6 mb-3">
        {capitalizeFirstLetter(props.ders)} Terimleri
      </h1>

      <table className="table text-white">
        <thead>
          <tr>
            <th>Kelime</th>
            <th>Açıklama</th>
          </tr>
        </thead>

        <tbody>
          {terimler.map((item, index) => (
            <tr key={index}>
              <td>{item.key}</td>
              <td>{item.val}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
