import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import { format } from "react-string-format";
import { CokluSecim } from "../components/CokluSecim";

export function KelimeKartlari() {
  const [kartlar, setKartlar] = useState([]);
  const [seciliKartlar, setSeciliKartlar] = useState([]);

  const [kartDetaylari, setKartDetaylari] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    axios
      .get("/api/v1.0/kelime_kartlari")
      .then((response) => {
        let dat = response.data.data.map((item) => {
          return { name: "Kart " + item, code: item, checked: false };
        });
        setKartlar(dat);
        setSeciliKartlar([dat[0].code]);
        seciliKartlariGetir([dat[0].code]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function kartSecDugmesineBasildi() {
    setShow(true);
  }

  async function seciliKartlariGetir(secilenler) {
    let request_data = {
      secili_kartlar: secilenler,
    };
    const result = await axios.post(
      "/api/v1.0/kelime_kartlari/detay",
      request_data
    );
    setKartDetaylari(result.data.data);
  }

  async function kartSecimiGonderDugmesineBasildi(secilenler) {
    seciliKartlariGetir(secilenler);
    setSeciliKartlar(secilenler);
    setShow(false);
  }

  async function kartSecKapatBasildi() {
    setShow(false);
  }

  return (
    <div className="container-fluid mainContent py-5 text-white">
      <div className="container">
        <h1 className="display-6 mb-3 text-center">
          Kelime Kartları{" "}
          <Button variant="primary" onClick={kartSecDugmesineBasildi}>
            Kart Seç
          </Button>
        </h1>

        <CokluSecim
          baslik="Kart Seçiniz"
          gorunur={show}
          secenekler={kartlar}
          seciliSecenekler={seciliKartlar}
          tamamaBasildi={kartSecimiGonderDugmesineBasildi}
          kapataBasildi={kartSecKapatBasildi}
          satiraYay={true}
        ></CokluSecim>

        <Accordion defaultActiveKey="0">
          {Object.keys(kartDetaylari).map((key, index) => (
            <div key={index} className="row mt-4 justify-content-center">
              <Accordion.Item eventKey={{ index }}>
                <Accordion.Header className="display-6">
                  <h3 className=" mt-3">{format("Kart {0}", key)}</h3>
                </Accordion.Header>
                <Accordion.Body
                  key={index}
                  className="row justify-content-center"
                >
                  {kartDetaylari[key].map((item, index) => {
                    return (
                      <div key={index} className="col-12 col-md-6 col-lg-4">
                        <div className="row wordWord">
                          <div className="col-6 AR">{item["ar"]}</div>
                          <div className="col-6 TR">{item["tr"]}</div>
                        </div>
                      </div>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </div>
          ))}
        </Accordion>
      </div>
    </div>
  );
}
