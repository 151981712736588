import React, { useEffect, useState } from "react";
import axios from "axios";

export function Muttaride() {
  const [sigalar, setSigalar] = useState([]);
  const [seciliSiga, setSeciliSiga] = useState("");
  const [kelimeler, setKelimeler] = useState([]);
  const [seciliKelimeId, setSeciliKelimeId] = useState("");

  const [sigaTabloVerisi, setSigaTabloVerisi] = useState([]);
  const [mutekellimVerisi, setMutekellimVerisi] = useState([]);

  useEffect(() => {
    const sigalar_istegi = axios.get("/api/v1.0/siga");
    const kelimeler_istegi = axios.get("/api/v1.0/kelime_listesi");

    axios
      .all([sigalar_istegi, kelimeler_istegi])
      .then(
        axios.spread((...responses) => {
          const sigalar_cevap = responses[0];
          const kelimeler_cevap = responses[1];

          let sigalarDat = sigalar_cevap.data.data.map((item) => {
            return { label: item.name, value: item.code };
          });
          setSigalar(sigalarDat);
          setSeciliSiga(sigalarDat[0].value);

          let kelimeDat = kelimeler_cevap.data.data.map((item) => {
            return {
              label: item.mazi + " - " + item.muzari + " (" + item.mana + ")",
              value: item.id,
            };
          });
          setKelimeler(kelimeDat);
          setSeciliKelimeId(kelimeDat[0].value);
          tabloVerisiGetir(sigalarDat[0].value, kelimeDat[0].value);
        })
      )
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  async function kelimeSecilince(secilenKelime) {
    setSeciliKelimeId(secilenKelime);
    tabloVerisiGetir(seciliSiga, secilenKelime);
  }

  async function sigaSecilince(secilenSiga) {
    setSeciliSiga(secilenSiga);
    tabloVerisiGetir(secilenSiga, seciliKelimeId);
  }

  async function tabloVerisiGetir(secilenSiga, secilenKelime) {
    let request_data = {
      siga: secilenSiga,
      kelime_id: parseInt(secilenKelime),
    };
    axios
      .post("/api/v1.0/emsile/muttaride", request_data)
      .then((response) => {
        setSigaTabloVerisi(response.data.data["siga_tablosu"]);
        setMutekellimVerisi(response.data.data["mutekellim"]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="container-fluid mainContent py-5 text-white text-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <h1 className="display-6 mb-3">Emsile-i Muttaride</h1>

            <div className="row">
              <div className="col-4">
                <select
                  className="form-control select"
                  value={seciliSiga}
                  onChange={(e) => sigaSecilince(e.target.value)}
                >
                  {sigalar.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-8">
                <select
                  className="form-control select"
                  value={seciliKelimeId}
                  onChange={(e) => kelimeSecilince(e.target.value)}
                >
                  {kelimeler.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {sigaTabloVerisi.map((item, index) => (
          <div key={index} className="wordSet">
            <div className={sigaTabloVerisi[index].aciklama && "label"}>
              {sigaTabloVerisi[index].aciklama}
            </div>
            <div className="row">
              <div className="col-4">
                <div className="wordCard">
                  <div className="AR">{sigaTabloVerisi[index].cem}</div>
                  <div className="TR">Cem</div>
                </div>
              </div>
              <div className="col-4">
                <div className="wordCard">
                  <div className="AR">{sigaTabloVerisi[index].tesniye}</div>
                  <div className="TR">Tesniye</div>
                </div>
              </div>
              <div className="col-4">
                <div className="wordCard">
                  <div className="AR">{sigaTabloVerisi[index].mufred}</div>
                  <div className="TR">Müfred</div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {Array.isArray(mutekellimVerisi) && mutekellimVerisi.length > 0 && (
          <div className="wordSet">
            <div className="label">{mutekellimVerisi[0].aciklama}</div>
            <div className="row">
              <div className="col-6">
                <div className="wordCard">
                  <div className="AR">{mutekellimVerisi[0].meal_gayr}</div>
                  <div className="TR">Meal Gayr</div>
                </div>
              </div>
              <div className="col-6">
                <div className="wordCard">
                  <div className="AR">{mutekellimVerisi[0].vahdehu}</div>
                  <div className="TR">Vahdehu</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
