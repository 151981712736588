import React, { useEffect, useState } from "react";
import { IbareOkuyucu } from "../components/IbareOkuyucu";

export function IzziIbare() {
  return (
    <div
      className="container-fluid mainContent p-4"
      style={{ backgroundColor: "#f9edd1" }}
    >
      <IbareOkuyucu></IbareOkuyucu>
    </div>
  );
}
