import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

export function CokluSecim({
  baslik,
  gorunur,
  secenekler,
  seciliSecenekler,
  tamamaBasildi,
  kapataBasildi,
  satiraYay,
}) {
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [hepsiniSecIsaretlimi, setHepsiniSecIsaretlimi] = useState(false);
  const [secimYapilmadiUyarisiGoster, setSecimYapilmadiUyarisiGoster] =
    useState(false);

  useEffect(() => {
    setShow(gorunur);
    setOptions(secenekler);
    setSelectedOptions(seciliSecenekler);
    setHepsiniSecIsaretlimi(secenekler.length === seciliSecenekler.length);
  }, [gorunur, secenekler]);

  async function kartSecildi(event) {
    let _secilenler = [...selectedOptions];
    if (event.checked) {
      _secilenler.push(event.id);
    } else {
      for (let i = 0; i < _secilenler.length; i++) {
        const seciliKart = _secilenler[i];

        if (seciliKart == event.id) {
          _secilenler.splice(i, 1);
          break;
        }
      }
    }
    setSelectedOptions(_secilenler);
    setHepsiniSecIsaretlimi(_secilenler.length === options.length);
  }

  async function secimleriGonderDugmesineBasildi() {
    selectedOptions.length == 0
      ? setSecimYapilmadiUyarisiGoster(true)
      : tamamaBasildi(selectedOptions);
  }

  async function hepsiniSecDegisti(event) {
    setHepsiniSecIsaretlimi(event.checked);
    let _secilenler = [];
    if (event.checked) {
      options.map((item) => {
        _secilenler.push(item.code);
      });
    }
    setSelectedOptions(_secilenler);
  }

  async function kapatDugmesineBasildi() {
    setSecimYapilmadiUyarisiGoster(false);
    kapataBasildi();
  }

  return (
    <Modal show={show} onHide={kapataBasildi} centered>
      <Modal.Header closeButton>
        <Modal.Title>{baslik}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Check
            className="mb-3"
            key="hepsiniSec"
            id="hepsiniSec"
            type="checkbox"
            label="Hepsini Seç"
            checked={hepsiniSecIsaretlimi}
            onChange={(e) => hepsiniSecDegisti(e.target)}
          />
          {options.map((item, index) => {
            return (
              <Form.Check
                inline={satiraYay}
                key={index}
                type="checkbox"
                label={item.name}
                id={item.code}
                checked={
                  selectedOptions.find((s) => s == item.code) != undefined
                }
                onChange={(e) => kartSecildi(e.target)}
              />
            );
          })}
          {selectedOptions.length == 0 && (
            <div className="mt-4 text-warning">En az bir seçim yapınız</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={kapatDugmesineBasildi}>
          İptal
        </Button>
        <Button variant="primary" onClick={secimleriGonderDugmesineBasildi}>
          Onayla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
