import { React, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { CokluSecim } from "../components/CokluSecim";
import { SoruKarti } from "../components/SoruKarti";

export function KelimeBulmaca() {
  const [ceviriYonu, setCeviriYonu] = useState("");
  const [kartlar, setKartlar] = useState([]);
  const [secilenKartlar, setSecilenKartlar] = useState([]);
  const [sorular, setSorular] = useState([]);

  const [kartSecimDialogGorunsun, setKartSecimDialogGorunsun] = useState(false);

  useEffect(() => {
    axios
      .get("/api/v1.0/kelime_kartlari")
      .then((response) => {
        let dat = response.data.data.map((item) => {
          return { name: "Kart " + item, code: item, checked: false };
        });
        setKartlar(dat);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function yeniBulmacaDugmesineBasildi(secilenler) {
    let request_data = {
      secili_kartlar: secilenler,
      ceviri_yonu: "cift_yonlu",
    };
    axios
      .post("/api/v1.0/kelime/bulmaca", request_data)
      .then((response) => {
        setSorular(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function kartSecDugmesineBasildi() {
    setKartSecimDialogGorunsun(true);
  }

  async function kartSecimiGonderDugmesineBasildi(secilenler) {
    setSecilenKartlar(secilenler);
    setKartSecimDialogGorunsun(false);
    yeniBulmacaDugmesineBasildi(secilenler);
  }

  async function kartSecKapatBasildi() {
    setKartSecimDialogGorunsun(false);
  }

  return (
    <div className="container-fluid mainContent py-5 text-white">
      <div className="container">
        <h1 className="display-6 mb-3 text-center">
          Kelime Bulmaca{" "}
          <Button variant="primary" onClick={kartSecDugmesineBasildi}>
            Kart Seç
          </Button>
        </h1>
        <hr />
        <CokluSecim
          baslik="Kart Seçiniz"
          gorunur={kartSecimDialogGorunsun}
          secenekler={kartlar}
          seciliSecenekler={secilenKartlar}
          tamamaBasildi={kartSecimiGonderDugmesineBasildi}
          kapataBasildi={kartSecKapatBasildi}
          satiraYay={true}
        ></CokluSecim>

        <div className="row mt-4 justify-content-center">
          {/* <button
            type="button"
            className="btn btn-outline-light"
            onClick={yeniBulmacaDugmesineBasildi}
          >
            Yeni Bulmaca
          </button> */}
          {sorular.map((item, index) => {
            return <SoruKarti key={index} kart_detayi={item} />;
          })}
        </div>
      </div>
    </div>
  );
}
