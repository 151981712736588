import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { CokluSecim } from "../components/CokluSecim";

export function SigaBulucu() {
  const [sigalar, setSigalar] = useState([]);
  const [seciliSigalar, setSeciliSigalar] = useState([]);
  const [kelime, setKelime] = useState("");
  const [kelimeKaynaklari, setKelimeKaynaklari] = useState({});
  const [siga, setSiga] = useState("");
  const [sigaGorunsun, setSigaGorunsun] = useState(false);
  const [sigaTabloVerisi, setSigaTabloVerisi] = useState([]);
  const [sigaTablosuGorunsun, setSigaTablosuGorunsun] = useState(false);
  const [mutekellimVerisi, setMutekellimVerisi] = useState([]);
  const [mutekellimTablosuGorunsun, setMutekellimTablosuGorunsun] =
    useState(false);

  const [sonrakiKelime, setSonrakiKelime] = useState("");
  const [sonrakiKelimeGorunsun, setSonrakiKelimeGorunsun] = useState(false);
  const [sonrakiSiga, setSonrakiSiga] = useState("");
  const [sonrakiSigaGorunsun, setSonrakiSigaGorunsun] = useState(false);
  const [sonrakiSigaTabloVerisi, setSonrakiSigaTabloVerisi] = useState([]);
  const [sonrakiSigaTablosuGorunsun, setSonrakiSigaTablosuGorunsun] =
    useState(false);
  const [sonrakiMutekellimVerisi, setSonrakiMutekellimVerisi] = useState([]);
  const [
    sonrakiMutekellimTablosuGorunsun,
    setSonrakiMutekellimTablosuGorunsun,
  ] = useState(false);

  const [sigaSecimDialogGorunsun, setSigaSecimDialogGorunsun] = useState(false);
  const sigaSecimDialogKapatBasildi = () => setSigaSecimDialogGorunsun(false);
  const sigaSecimDialogAcBasildi = () => setSigaSecimDialogGorunsun(true);

  useEffect(() => {
    axios
      .get("/api/v1.0/siga")
      .then((response) => {
        let dat = response.data.data;
        setSigalar(dat);
        let seciliSigalarDat = dat.map((item) => {
          return item.code;
        });
        setSeciliSigalar(seciliSigalarDat);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function onSubmitYeniKelime() {
    let seciliSigalarRequestData = seciliSigalar.map((item) => {
      return { name: item, code: item };
    });
    let request_data = {
      seciliSigalar: seciliSigalarRequestData,
    };
    const result = await axios.post("/api/v1.0/kelime", request_data);
    setKelime(result.data["kelime"]);
    setKelimeKaynaklari(result.data["kaynaklar"]);
    setSiga(result.data["siga"]);
    setSigaTabloVerisi(result.data["siga_tablosu"]);
    setMutekellimVerisi(result.data["mutekellim"]);
    setSigaGorunsun(false);
    setSigaTablosuGorunsun(false);
    setMutekellimTablosuGorunsun(false);
    setSonrakiKelime("");
    setSonrakiSiga("");
    setSonrakiSigaGorunsun(false);
    setSonrakiKelimeGorunsun(false);
    setSonrakiSigaTablosuGorunsun(false);
    setSonrakiMutekellimTablosuGorunsun(false);
  }

  async function onSubmitSigaGoster() {
    setSigaGorunsun(true);
    setSigaTablosuGorunsun(true);
    setMutekellimTablosuGorunsun(true);
  }

  async function onSubmitBaskaSiga() {
    let seciliSigalarRequestData = seciliSigalar.map((item) => {
      return { name: item, code: item };
    });
    let request_data = {
      seciliSigalar: seciliSigalarRequestData,
      seciliKaynaklar: kelimeKaynaklari,
    };
    const result = await axios.post("/api/v1.0/kelime", request_data);
    setSonrakiKelime(result.data["kelime"]);
    setSonrakiSiga(result.data["siga"]);
    setSonrakiSigaGorunsun(true);
    setSonrakiKelimeGorunsun(false);
    setSonrakiSigaTabloVerisi(result.data["siga_tablosu"]);
    setSonrakiMutekellimVerisi(result.data["mutekellim"]);
    setSonrakiSigaTablosuGorunsun(false);
    setSonrakiMutekellimTablosuGorunsun(false);
  }

  async function onSubmitBaskaSigaKelimesiniGoster() {
    setSonrakiKelimeGorunsun(true);
    setSonrakiSigaTablosuGorunsun(true);
    setSonrakiMutekellimTablosuGorunsun(true);
  }

  async function sigaSecDugmesineBasildi() {
    setSigaSecimDialogGorunsun(true);
  }

  async function sigaSecimiGonderDugmesineBasildi(secilenler) {
    setSeciliSigalar(secilenler);
    setSigaSecimDialogGorunsun(false);
  }

  async function sigaSecKapatBasildi() {
    setSigaSecimDialogGorunsun(false);
  }

  return (
    <div className="container-fluid mainContent py-5 text-white">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-6">
            <h1 className="display-6 mb-3">
              Siga Bulmaca{" "}
              <Button variant="primary" onClick={sigaSecDugmesineBasildi}>
                Siga Seç
              </Button>
            </h1>
            <hr />

            <CokluSecim
              baslik="Siga Seçiniz"
              gorunur={sigaSecimDialogGorunsun}
              secenekler={sigalar}
              seciliSecenekler={seciliSigalar}
              tamamaBasildi={sigaSecimiGonderDugmesineBasildi}
              kapataBasildi={sigaSecKapatBasildi}
              satiraYay={false}
            ></CokluSecim>

            <button
              className="btn btn-outline-light"
              type="button"
              onClick={onSubmitYeniKelime}
            >
              Yeni Kelime
            </button>

            {kelime && (
              <div className="text-center my-3">
                <h2 className="display-1 text-center">{kelime}</h2>
                <button
                  className="btn btn-outline-light mt-1"
                  type="button"
                  onClick={onSubmitSigaGoster}
                >
                  Siga Göster
                </button>
              </div>
            )}

            {sigaGorunsun && <p className="text-center">{siga}</p>}
          </div>
        </div>

        {sigaTablosuGorunsun &&
          sigaTabloVerisi.map((item, index) => (
            <div key={index} className="wordSet">
              <div className={sigaTabloVerisi[index].aciklama && "label"}>
                {sigaTabloVerisi[index].aciklama}
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="wordCard">
                    <div className="AR">{sigaTabloVerisi[index].cem}</div>
                    <div className="TR">Cem</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="wordCard">
                    <div className="AR">{sigaTabloVerisi[index].tesniye}</div>
                    <div className="TR">Tesniye</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="wordCard">
                    <div className="AR">{sigaTabloVerisi[index].mufred}</div>
                    <div className="TR">Müfred</div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {Array.isArray(mutekellimVerisi) &&
          mutekellimVerisi.length > 0 &&
          mutekellimTablosuGorunsun &&
          mutekellimVerisi && (
            <div className="wordSet">
              <div className="label">{mutekellimVerisi[0].aciklama}</div>
              <div className="row">
                <div className="col-6">
                  <div className="wordCard">
                    <div className="AR">{mutekellimVerisi[0].meal_gayr}</div>
                    <div className="TR">Meal Gayr</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="wordCard">
                    <div className="AR">{mutekellimVerisi[0].vahdehu}</div>
                    <div className="TR">Vahdehu</div>
                  </div>
                </div>
              </div>
            </div>
          )}

        <div className="row justify-content-center">
          <div className="col-12 col-md-4 text-center text-dark mt-4">
            {sigaTablosuGorunsun && (
              <button
                className="btn btn-success"
                type="button"
                onClick={onSubmitBaskaSiga}
              >
                Başka Sigaya Taşı
              </button>
            )}

            {sonrakiSigaGorunsun && (
              <div className="text-center mt-3 text-primary">
                <p>{sonrakiSiga}</p>

                <button
                  className="btn btn-success"
                  type="button"
                  onClick={onSubmitBaskaSigaKelimesiniGoster}
                >
                  Cevap Göster
                </button>
              </div>
            )}
          </div>
        </div>

        {sonrakiKelimeGorunsun && (
          <div className="text-center my-3 text-primary">
            <h2 className="display-1 text-center">{sonrakiKelime}</h2>
          </div>
        )}

        {sonrakiSigaTablosuGorunsun &&
          sonrakiSigaTabloVerisi.map((item, index) => (
            <div key={index} className="wordSet">
              <div
                className={sonrakiSigaTabloVerisi[index].aciklama && "label"}
              >
                {sonrakiSigaTabloVerisi[index].aciklama}
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="wordCard">
                    <div className="AR">
                      {sonrakiSigaTabloVerisi[index].cem}
                    </div>
                    <div className="TR">Cem</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="wordCard">
                    <div className="AR">
                      {sonrakiSigaTabloVerisi[index].tesniye}
                    </div>
                    <div className="TR">Tesniye</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="wordCard">
                    <div className="AR">
                      {sonrakiSigaTabloVerisi[index].mufred}
                    </div>
                    <div className="TR">Müfred</div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {Array.isArray(sonrakiMutekellimVerisi) &&
          sonrakiMutekellimVerisi.length > 0 &&
          sonrakiMutekellimTablosuGorunsun &&
          sonrakiMutekellimVerisi && (
            <div className="wordSet">
              <div className="label">{sonrakiMutekellimVerisi[0].aciklama}</div>
              <div className="row">
                <div className="col-6">
                  <div className="wordCard">
                    <div className="AR">
                      {sonrakiMutekellimVerisi[0].meal_gayr}
                    </div>
                    <div className="TR">Meal Gayr</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="wordCard">
                    <div className="AR">
                      {sonrakiMutekellimVerisi[0].vahdehu}
                    </div>
                    <div className="TR">Vahdehu</div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
