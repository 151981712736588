import React, { useEffect, useState } from "react";
import axios from "axios";

export function Video() {
  const [videoLinkleri, setVideoLinkleri] = useState([]);
  useEffect(() => {
    axios
      .get("/api/v1.0/videolar")
      .then((response) => {
        setVideoLinkleri(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="container-fluid mainContent py-5 text-white text-center">
      <h1 className="display-6 mb-3">Online Ders Kesitleri</h1>
      Online Gramer Köşkü'nde yaptığımız bazı derslerin kesitlerine bu sayfadan
      erişebilirsiniz.
      <hr />
      <div className="row">
        <div className="col-12">
          {videoLinkleri.map((item, index) => (
            <iframe
              key={index}
              style={{ marginTop: 15, marginRight: 15 }}
              width="300"
              height="200"
              src={item}
            ></iframe>
          ))}
        </div>
      </div>
    </div>
  );
}
