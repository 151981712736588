import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Toast from "react-bootstrap/Toast";
import ListGroup from "react-bootstrap/ListGroup";

export function ButtonWithToastList(props) {
  const [showToast, setShowToast] = useState(false);

  const toggleToast = () => setShowToast(!showToast);

  useEffect(() => {
    setShowToast(false);
  }, [props.kelime]);

  return (
    <div className="text-black">
      <Button onClick={toggleToast} className="mb-2">
        {props.buttonText}
      </Button>
      <Toast show={showToast} onClose={toggleToast} animation={false}>
        {props.toastList.map((item, index) => (
          <ListGroup as="ol" key={index}>
            <ListGroup.Item as="li" className="justify-content-between">
              <div className="ms-1 me-auto">
                <div
                  className="fw-bold text-success"
                  style={{ fontSize: "45px" }}
                >
                  {item.fiil}
                </div>
                {item.ilal}
              </div>
            </ListGroup.Item>
          </ListGroup>
        ))}
      </Toast>
    </div>
  );
}
