import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { CokluSecim } from "../components/CokluSecim";

export function BabBulmaca() {
  const [bablar, setBablar] = useState([]);
  const [seciliBablar, setSeciliBablar] = useState([]);
  const [kelime, setKelime] = useState("");
  const [harfSayisi, setHarfSayisi] = useState("");
  const [kelimeMazi, setKelimeMazi] = useState("");
  const [kelimeMuzari, setKelimeMuzari] = useState("");
  const [kelimeMasdar, setKelimeMasdar] = useState("");
  const [kelimeEmriHazir, setKelimeEmriHazir] = useState("");
  const [kelimeIsmiFail, setKelimeIsmiFail] = useState("");

  const [bab, setBab] = useState("");
  const [bina, setBina] = useState("");
  const [babGorunsun, setBabGorunsun] = useState(false);

  const [sonrakiKelimeGorunsun, setSonrakiKelimeGorunsun] = useState(false);
  const [sonrakiKelimeMazi, setSonrakiKelimeMazi] = useState("");
  const [sonrakiKelimeMuzari, setSonrakiKelimeMuzari] = useState("");
  const [sonrakiKelimeMasdar, setSonrakiKelimeMasdar] = useState("");

  const [sonrakiBab, setSonrakiBab] = useState("");
  const [sonrakiBina, setSonrakiBina] = useState("");
  const [sonrakiBabGorunsun, setSonrakiBabGorunsun] = useState(false);

  const [babSecimDialogGorunsun, setBabSecimDialogGorunsun] = useState(false);

  useEffect(() => {
    axios
      .get("/api/v1.0/bina/bab")
      .then((response) => {
        let dat = response.data.data.map((item) => {
          return { name: item.isim, code: item.id };
        });
        setBablar(dat);
        let seciliBablarDat = dat.map((item) => {
          return item.code;
        });
        setSeciliBablar(seciliBablarDat);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function onSubmitYeniKelime() {
    let request_data = {
      kelime: "",
      harf_sayisi: "",
      secili_bablar: seciliBablar,
    };
    const result = await axios.post("/api/v1.0/bina/kelime", request_data);
    setKelime(result.data["kelime"]);
    setKelimeMazi(result.data["mazi"]);
    setKelimeMuzari(result.data["muzari"]);
    setKelimeMasdar(result.data["masdar"]);
    setHarfSayisi(result.data["harf_sayisi"]);
    // setKelimeEmriHazir(result.data["emri_hazir"]);
    // setKelimeIsmiFail(result.data["ismi_fail"]);
    setBab(result.data["bab"]);
    setBina(result.data["bina"]);

    setBabGorunsun(false);
    setSonrakiBab("");
    setSonrakiBabGorunsun(false);
    setSonrakiKelimeGorunsun(false);
  }

  async function onSubmitBabGoster() {
    setBabGorunsun(true);
  }

  async function baskaBabaTasiDugmesineBasildi() {
    let request_data = {
      kelime: kelime,
      harf_sayisi: harfSayisi,
      secili_bablar: seciliBablar,
    };
    const result = await axios.post("/api/v1.0/bina/kelime", request_data);
    setSonrakiBab(result.data["bab"]);
    setSonrakiBina(result.data["bina"]);
    setSonrakiKelimeMazi(result.data["mazi"]);
    setSonrakiKelimeMuzari(result.data["muzari"]);
    setSonrakiKelimeMasdar(result.data["masdar"]);
    setSonrakiBabGorunsun(true);
    setSonrakiKelimeGorunsun(false);
  }

  async function baskaBabCevapGosterDugmesineBasildi() {
    setSonrakiKelimeGorunsun(true);
  }

  async function babSecDugmesineBasildi() {
    setBabSecimDialogGorunsun(true);
  }

  async function babSecimiGonderDugmesineBasildi(secilenler) {
    setBabSecimDialogGorunsun(false);
    setSeciliBablar(secilenler);
  }

  async function babSecKapatBasildi() {
    setBabSecimDialogGorunsun(false);
  }

  return (
    <div className="container-fluid mainContent py-5 text-white">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-6">
            <h1 className="display-6 mb-3">
              Bab Bulmaca{" "}
              <Button variant="primary" onClick={babSecDugmesineBasildi}>
                Bab Seç
              </Button>
            </h1>
            <hr />
            <button
              className="btn btn-outline-light"
              type="button"
              onClick={onSubmitYeniKelime}
            >
              Yeni Kelime
            </button>
          </div>
        </div>

        <CokluSecim
          baslik="Bab Seçiniz"
          gorunur={babSecimDialogGorunsun}
          secenekler={bablar}
          seciliSecenekler={seciliBablar}
          tamamaBasildi={babSecimiGonderDugmesineBasildi}
          kapataBasildi={babSecKapatBasildi}
          satiraYay={false}
        ></CokluSecim>

        {kelimeMazi && (
          <div className="text-center my-3">
            <div className="wordSet">
              {babGorunsun && <div className={"label"}>{bab}</div>}
              {!babGorunsun && (
                <div className={"labelClickable"} onClick={onSubmitBabGoster}>
                  Bab Göster
                </div>
              )}
              <div className="row">
                <div className="col-4">
                  <div className="wordCard">
                    <div className="AR">{kelimeMasdar}</div>
                    <div className="TR">Masdar</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="wordCard">
                    <div className="AR">{kelimeMuzari}</div>
                    <div className="TR">Muzari</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="wordCard">
                    <div className="AR">{kelimeMazi}</div>
                    <div className="TR">Mazi</div>
                  </div>
                </div>
              </div>
              {babGorunsun && <div className="mt-3">Binası: {bina}</div>}
              {/* <h2 className="mt-3">{kelimeEmriHazir}</h2>
              <h2 className="mt-3">{kelimeIsmiFail}</h2> */}
            </div>
          </div>
        )}

        <div className="row justify-content-center">
          <div className="col-12 col-md-4 text-center text-dark mt-4">
            {babGorunsun && (
              <button
                className="btn btn-success"
                type="button"
                onClick={baskaBabaTasiDugmesineBasildi}
              >
                Başka Bab'a Taşı
              </button>
            )}
          </div>
        </div>

        {sonrakiBabGorunsun && (
          <div className="text-center my-3">
            <div className="wordSet">
              <div
                className={sonrakiKelimeGorunsun ? "label" : "labelClickable"}
                onClick={baskaBabCevapGosterDugmesineBasildi}
              >
                {sonrakiBab}
              </div>
              {sonrakiKelimeGorunsun && (
                <div className="row">
                  <div className="col-4">
                    <div className="wordCard">
                      <div className="AR">{sonrakiKelimeMasdar}</div>
                      <div className="TR">Masdar</div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="wordCard">
                      <div className="AR">{sonrakiKelimeMuzari}</div>
                      <div className="TR">Muzari</div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="wordCard">
                      <div className="AR">{sonrakiKelimeMazi}</div>
                      <div className="TR">Mazi</div>
                    </div>
                  </div>
                </div>
              )}
              {sonrakiKelimeGorunsun && (
                <div className="mt-3">Binası: {sonrakiBina}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
