import React, { useEffect, useState } from "react";
import axios from "axios";

export function Bablar() {
  const [bablar, setBablar] = useState([]);
  const [seciliBab, setSeciliBab] = useState("");
  const [kelimeler, setKelimeler] = useState([]);
  const [sulasiFiiller, setSulasiFiiller] = useState([]);
  const [rubaiFiiller, setRubaiFiiller] = useState([]);
  const [seciliKelimeId, setSeciliKelimeId] = useState("");

  const [babTablolariVerisi, setBabTablolariVerisi] = useState([]);

  useEffect(() => {
    const bablar_istegi = axios.get("/api/v1.0/bina/bab");
    const sulasi_fiiller_istegi = axios.get("/api/v1.0/kelime_listesi");
    const rubai_fiiller_istegi = axios.get("/api/v1.0/kelime_listesi", {
      params: { tip: "rubai" },
    });

    axios
      .all([bablar_istegi, sulasi_fiiller_istegi, rubai_fiiller_istegi])
      .then(
        axios.spread((...responses) => {
          const bablar_cevap = responses[0];
          const sulasi_fiiller_cevap = responses[1];
          const rubai_fiiller_cevap = responses[2];

          let bablarDat = bablar_cevap.data.data.map((item) => {
            return { label: item.isim, value: item.id };
          });
          setBablar(bablarDat);
          setSeciliBab(bablarDat[0].value);

          let sulasi_fiiller = sulasi_fiiller_cevap.data.data.map((item) => {
            return {
              label: item.mazi + " (" + item.mana + ")",
              value: item.id,
              tip: item.tip,
            };
          });
          let rubai_fiiller = rubai_fiiller_cevap.data.data.map((item) => {
            return {
              label: item.mazi + " (" + item.mana + ")",
              value: item.id,
              tip: item.tip,
            };
          });
          setSulasiFiiller(sulasi_fiiller);
          setRubaiFiiller(rubai_fiiller);
          setKelimeler(sulasi_fiiller);
          setSeciliKelimeId(sulasi_fiiller[0].value);
          tabloVerisiGetir(bablarDat[0].value, sulasi_fiiller[0].value);
        })
      )
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  async function kelimeSecilince(secilenKelime) {
    setSeciliKelimeId(secilenKelime);
    tabloVerisiGetir(seciliBab, secilenKelime);
  }

  async function babSecilince(secilenBab) {
    let mevcut_secili_fiil_tipi = seciliBab.split(";")[0];
    let sonraki_secilen_fiil_tipi = secilenBab.split(";")[0];
    let sonraki_kelime_id = seciliKelimeId;

    if (
      mevcut_secili_fiil_tipi !== "rubai" &&
      sonraki_secilen_fiil_tipi === "rubai"
    ) {
      setKelimeler(rubaiFiiller);
      sonraki_kelime_id = rubaiFiiller[0].value;
    }

    if (
      mevcut_secili_fiil_tipi === "rubai" &&
      sonraki_secilen_fiil_tipi !== "rubai"
    ) {
      setKelimeler(sulasiFiiller);
      sonraki_kelime_id = sulasiFiiller[0].value;
    }

    setSeciliKelimeId(sonraki_kelime_id);
    setSeciliBab(secilenBab);
    tabloVerisiGetir(secilenBab, sonraki_kelime_id);
  }

  async function tabloVerisiGetir(secilenBab, secilenKelime) {
    let request_data = {
      fiil_tipi: secilenBab,
      kelime_id: parseInt(secilenKelime),
    };
    axios
      .post("/api/v1.0/bina/bab", request_data)
      .then((response) => {
        setBabTablolariVerisi(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="container-fluid mainContent py-5 text-white text-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <h1 className="display-6 mb-3">Bablar</h1>

            <div className="row">
              <div className="col-4">
                <select
                  className="form-control select"
                  value={seciliBab}
                  onChange={(e) => babSecilince(e.target.value)}
                >
                  {bablar.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-8">
                <select
                  className="form-control select"
                  value={seciliKelimeId}
                  onChange={(e) => kelimeSecilince(e.target.value)}
                >
                  {kelimeler.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {
          <div className="text-center my-3">
            {babTablolariVerisi.map((item, item_index) => (
              <div key={item_index} className="wordSet">
                <div className="label">{item.bab}</div>
                <div className="row">
                  <div className="col-4">
                    <div className="wordCard">
                      <div className="AR">{item.masdar}</div>
                      <div className="TR">Masdar</div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="wordCard">
                      <div className="AR">{item.muzari}</div>
                      <div className="TR">Muzari</div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="wordCard">
                      <div className="AR">{item.mazi}</div>
                      <div className="TR">Mazi</div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">Binası: {item.bina}</div>
                {/* <h2 className="mt-3">{kelimeEmriHazir}</h2>
              <h2 className="mt-3">{kelimeIsmiFail}</h2> */}
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  );
}
