import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

export function IbareKelimesi(props) {
  // props.ar_kelime, props.kelime_sozluk, props.harekeli_goster, props.kelime_cevirisi_goster
  let harekesiz_kelime = props.ar.m;
  let harekeli_kelime = props.ar.h;
  harekesiz_kelime = harekesiz_kelime.replace("_", "");
  harekesiz_kelime = harekesiz_kelime.replace("-", " ");
  harekeli_kelime = harekeli_kelime.replace("_", "");
  harekeli_kelime = harekeli_kelime.replace("-", " ");

  return props.kelime_cevirisi_goster === true ? (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="top"
      overlay={
        <Popover>
          <Popover.Header as="h1" className="text-center">
            {harekeli_kelime ? harekeli_kelime : harekesiz_kelime}
          </Popover.Header>
          <Popover.Body>{<strong>{props.kelime_sozluk}</strong>}</Popover.Body>
        </Popover>
      }
    >
      <span>
        {props.harekeli_goster && harekeli_kelime
          ? harekeli_kelime
          : harekesiz_kelime}
        <span> </span>
      </span>
    </OverlayTrigger>
  ) : (
    <span>
      {props.harekeli_goster && harekeli_kelime
        ? harekeli_kelime
        : harekesiz_kelime}
      <span> </span>
    </span>
  );
}
