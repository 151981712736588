import React, { useEffect, useState } from "react";
import axios from "axios";

export function FiilSecimi(props) {
  const [harfSayilari, setHarfSayilari] = useState([]);
  const [seciliHarfSayisi, setSeciliHarfSayisi] = useState("");
  const [yapilar, setYapilar] = useState([]);
  const [seciliYapi, setSeciliYapi] = useState("");
  const [aksamlar, setAksamlar] = useState([]);
  const [seciliAksam, setSeciliAksam] = useState("");
  const [bablar, setBablar] = useState([]);
  const [seciliBab, setSeciliBab] = useState("");
  const [kelimeler, setKelimeler] = useState([]);
  const [seciliKelime, setSeciliKelime] = useState("");

  useEffect(() => {
    axios
      .get("/api/v1.0/sarf/bina/bab")
      .then((response) => {
        let resp = response.data.data;
        console.log(resp);
        console.log(resp.harf_sayilari[0].code);
        setHarfSayilari(resp.harf_sayilari);
        setSeciliHarfSayisi(resp.harf_sayilari[0].code);
        setYapilar(resp.yapilar);
        setSeciliYapi(resp.yapilar[0].code);
        setAksamlar(resp.aksamlar);
        setSeciliAksam(resp.aksamlar[0].code);
        setBablar(resp.bablar);
        setSeciliBab(resp.bablar[0].code);
        setKelimeler(resp.kelimeler);
        setSeciliKelime(resp.kelimeler[0].code);
        props.kelimeSecildi(
          resp.kelimeler[0].code,
          resp.harf_sayilari[0].code,
          resp.yapilar[0].code,
          resp.bablar[0].code
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function harfSayisiSecilince(harfSayisi) {
    setSeciliHarfSayisi(harfSayisi);
    axios
      .get("/api/v1.0/sarf/bina/bab", {
        params: { harf_sayisi: harfSayisi },
      })
      .then((response) => {
        let resp = response.data.data;
        console.log(resp);
        setYapilar(resp.yapilar);
        setSeciliYapi(resp.yapilar[0].code);
        setAksamlar(resp.aksamlar);
        setSeciliAksam(resp.aksamlar[0].code);
        setBablar(resp.bablar);
        setSeciliBab(resp.bablar[0].code);
        setKelimeler(resp.kelimeler);
        setSeciliKelime(resp.kelimeler[0].code);
        props.kelimeSecildi(
          resp.kelimeler[0].code,
          harfSayisi,
          resp.yapilar[0].code,
          resp.bablar[0].code
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function yapiSecilince(yapi) {
    setSeciliYapi(yapi);
    axios
      .get("/api/v1.0/sarf/bina/bab", {
        params: { harf_sayisi: seciliHarfSayisi, yapi: yapi },
      })
      .then((response) => {
        let resp = response.data.data;
        console.log(resp);
        setAksamlar(resp.aksamlar);
        setSeciliAksam(resp.aksamlar[0].code);
        setBablar(resp.bablar);
        setSeciliBab(resp.bablar[0].code);
        setKelimeler(resp.kelimeler);
        setSeciliKelime(resp.kelimeler[0].code);
        props.kelimeSecildi(
          resp.kelimeler[0].code,
          seciliHarfSayisi,
          yapi,
          resp.bablar[0].code
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function aksamSecilince(aksam) {
    setSeciliAksam(aksam);
    axios
      .get("/api/v1.0/sarf/bina/bab", {
        params: {
          harf_sayisi: seciliHarfSayisi,
          yapi: seciliYapi,
          aksam: aksam,
        },
      })
      .then((response) => {
        let resp = response.data.data;
        console.log(resp);
        setBablar(resp.bablar);
        setSeciliBab(resp.bablar[0].code);
        setKelimeler(resp.kelimeler);
        setSeciliKelime(resp.kelimeler[0].code);
        props.kelimeSecildi(
          resp.kelimeler[0].code,
          seciliHarfSayisi,
          seciliYapi,
          resp.bablar[0].code
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function babSecilince(bab) {
    setSeciliBab(bab);
    axios
      .get("/api/v1.0/sarf/bina/bab", {
        params: {
          harf_sayisi: seciliHarfSayisi,
          yapi: seciliYapi,
          aksam: seciliAksam,
          bab: bab,
        },
      })
      .then((response) => {
        let resp = response.data.data;
        console.log("Bab secildi:", resp);
        setKelimeler(resp.kelimeler);
        setSeciliKelime(resp.kelimeler[0].code);
        props.kelimeSecildi(
          resp.kelimeler[0].code,
          seciliHarfSayisi,
          seciliYapi,
          bab
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function kelimeSecilince(kelime) {
    setSeciliKelime(kelime);
    props.kelimeSecildi(kelime, seciliHarfSayisi, seciliYapi, seciliBab);
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <h1 className="display-6 mb-3">Çekimler</h1>

          <div className="row">
            <div className="col-3">
              <select
                className="form-control select"
                value={seciliHarfSayisi}
                onChange={(e) => harfSayisiSecilince(e.target.value)}
              >
                {harfSayilari.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <select
                className="form-control select"
                value={seciliYapi}
                onChange={(e) => yapiSecilince(e.target.value)}
              >
                {yapilar.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <select
                className="form-control select"
                value={seciliAksam}
                onChange={(e) => aksamSecilince(e.target.value)}
              >
                {aksamlar.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <select
                className="form-control select"
                value={seciliBab}
                onChange={(e) => babSecilince(e.target.value)}
              >
                {bablar.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-3">
              <select
                className="form-control select"
                style={{ fontSize: "24px" }}
                value={seciliKelime}
                onChange={(e) => kelimeSecilince(e.target.value)}
              >
                {kelimeler.map((item, index) => (
                  <option key={index} value={item.code} className="h2">
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
