import React, { useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { IbareKelimesi } from "./IbareKelimesi";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    return;
  });

  return (
    <Button variant="outline-success" onClick={decoratedOnClick}>
      {children}
      Meal
    </Button>
  );
}

export function IbareCumlesi(props) {
  return (
    <Accordion key={props.mealGoster} defaultActiveKey="0">
      <div className="ibare-cumlesi mt-4 ">
        <Card>
          <Card.Header>
            <CustomToggle eventKey={props.mealGoster}></CustomToggle>
            <h2 style={{ direction: "rtl", textAlign: "start" }}>
              {props.cumle.ar.map((kelime, index) => {
                return (
                  <IbareKelimesi
                    key={index}
                    ar={kelime}
                    kelime_sozluk={props.sozluk[props.cumle_index][index]}
                    harekeli_goster={props.harekeGoster}
                    kelime_cevirisi_goster={props.kelimeCevirisiGoster}
                  ></IbareKelimesi>
                );
              })}
            </h2>
          </Card.Header>
          <Accordion.Collapse eventKey={props.mealGoster}>
            <Card.Body>
              <div className=" text-center">{props.cumle.tr}</div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </div>
    </Accordion>
  );
}
