import React, { useEffect, useState } from "react";

import axios from "axios";

export function AksamiSemani() {
  const [aksamiSemani, setAksamiSemani] = useState({});

  useEffect(() => {
    axios
      .get("/api/v1.0/bina/aksami_semani")
      .then((response) => {
        setAksamiSemani(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="container-fluid mainContent py-5 text-white text-center">
      <div className="container">
        <h1 className="display-6 mb-3 text-center">Aksam-ı Semaniye</h1>
        <div className="wordSet">
          <div className="label">Sülâsî</div>
          <div className="row">
            <div className="col-6 order-md-3 col-md-3 mt-md-0">
              <div className="wordCard">
                <div className="AR">وَجَدَ</div>
                <div className="alt">Mücerred Ğayrı salım</div>
              </div>
            </div>
            <div className="col-6 order-md-last col-md-3 mt-md-0">
              <div className="wordCard">
                <div className="AR">كَرُمَ</div>
                <div className="alt">Mücerred Salım</div>
              </div>
            </div>
            <div className="col-6 order-md-first col-md-3 mt-3 mt-md-0">
              <div className="wordCard">
                <div className="AR">أَوْعَدَ</div>
                <div className="alt">Mezîd Ğayrı salım</div>
              </div>
            </div>
            <div className="col-6 order-md-6 col-md-3 mt-3 mt-md-0">
              <div className="wordCard">
                <div className="AR">أَكْرَمَ</div>
                <div className="alt">Mezîd Salım</div>
              </div>
            </div>
          </div>
        </div>
        <div className="wordSet">
          <div className="label">Rubâî</div>
          <div className="row">
            <div className="col-6 order-md-3 col-md-3 mt-md-0">
              <div className="wordCard">
                <div className="AR">وَسْوَسَ</div>
                <div className="alt">Mücerred Ğayrı salım</div>
              </div>
            </div>
            <div className="col-6 order-md-last col-md-3 mt-md-0">
              <div className="wordCard">
                <div className="AR">دَحْرَجَ</div>
                <div className="alt">Mücerred Salım</div>
              </div>
            </div>
            <div className="col-6 order-md-first col-md-3 mt-3 mt-md-0">
              <div className="wordCard">
                <div className="AR">تَوَسْوَسَ</div>
                <div className="alt">Mezîd Ğayrı salım</div>
              </div>
            </div>
            <div className="col-6 order-md-6  col-md-3 mt-3 mt-md-0">
              <div className="wordCard">
                <div className="AR">تَدَحْرَجَ</div>
                <div className="alt">Mezîd Salım</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
