import { React, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { IbareCumlesi } from "./IbareCumlesi";
import axios from "axios";

export function IbareOkuyucu(props) {
  const [sayfalar, setSayfalar] = useState([]);
  const [harekeGoster, setHarekeGoster] = useState(true);
  const [mealGoster, setMealGoster] = useState(false);
  const [kelimeCevirisiGoster, setKelimeCevirisiGoster] = useState(true);
  const [ibare, setIbare] = useState([]);
  const [sozluk, setSozluk] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1.0/izzi/ibare/sayfa")
      .then((response) => {
        setSayfalar(response.data.sayfalar);
        return axios.get("/api/v1.0/izzi/ibare", {
          params: { sayfa: response.data.sayfalar[0][0] },
        });
      })
      .then((response) => {
        setIbare(response.data.ibare);
        setSozluk(response.data.sozluk);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function harekeDugmesineBasildi(e) {
    setHarekeGoster(e.target.checked);
  }

  async function mealDugmesineBasildi(e) {
    setMealGoster(e.target.checked);
  }

  async function kelimeCevirisiDugmesineBasildi(e) {
    setKelimeCevirisiGoster(e.target.checked);
  }

  async function sayfaSecilince(sayfa) {
    axios
      .get("/api/v1.0/izzi/ibare", { params: { sayfa: sayfa } })
      .then((response) => {
        setIbare(response.data.ibare);
        setSozluk(response.data.sozluk);
        console.log(response.data.sozluk);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <select
        className="form-control select mt-3"
        onChange={(e) => sayfaSecilince(e.target.value)}
      >
        {sayfalar.map((item) => (
          <option key={item[0]} value={item[0]}>
            {item[1]}
          </option>
        ))}
      </select>
      <Form className="mt-3">
        <Form.Check
          defaultChecked
          inline
          type="switch"
          id="hareke-switch"
          label="Hareke"
          onChange={harekeDugmesineBasildi}
        />
        <Form.Check
          inline
          type="switch"
          id="meal-switch"
          label="Bütün Mealler"
          onChange={mealDugmesineBasildi}
        />
        <Form.Check
          inline
          defaultChecked
          type="switch"
          id="sozluk-switch"
          label="Kelime Çevirisi"
          onChange={kelimeCevirisiDugmesineBasildi}
        />
      </Form>
      {ibare &&
        ibare.cumleler &&
        ibare.cumleler.map((cumle, index) => {
          return (
            <IbareCumlesi
              key={index}
              cumle={cumle}
              sozluk={sozluk}
              cumle_index={index}
              harekeGoster={harekeGoster}
              mealGoster={mealGoster ? "0" : "1"}
              kelimeCevirisiGoster={kelimeCevirisiGoster}
            ></IbareCumlesi>
          );
        })}
    </div>
  );
}
