import { React, useState } from "react";
import Form from "react-bootstrap/Form";

export function SoruKarti(props) {
  const [kullaniciCevapIndex, setKullaniciCevapIndex] = useState(-1);
  const [kullaniciIsaretledimi, setKullaniciIsaretledimi] = useState(false);
  const [kullaniciBildimi, setKullaniciBildimi] = useState(false);
  const [cevapDogruIkonuGorunsun, setCevapDogruIkonuGorunsun] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [cevapYanlisIkonuGorunsun, setCevapYanlisIkonuGorunsun] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  async function cevapSecildi(e) {
    setKullaniciIsaretledimi(true);
    setKullaniciCevapIndex(e.target.value);
    setKullaniciBildimi(e.target.value == props.kart_detayi.cevap_index);
  }

  async function ipucuDugmesineBasildi() {
    if (!kullaniciIsaretledimi) {
      var nextState = [false, false, false, false, false];
      nextState[props.kart_detayi.cevap_index] = true;
      setCevapDogruIkonuGorunsun(nextState);
    } else {
      var guncellenecekListe = cevapYanlisIkonuGorunsun;
      if (kullaniciBildimi) {
        guncellenecekListe = cevapDogruIkonuGorunsun;
      }
      const nextState = guncellenecekListe.map((c, i) => {
        return i == kullaniciCevapIndex;
      });

      kullaniciBildimi
        ? setCevapDogruIkonuGorunsun(nextState)
        : setCevapYanlisIkonuGorunsun(nextState);
    }

    setTimeout(ipucuIkonlariniGizle, 1000);
  }

  async function ipucuIkonlariniGizle() {
    var nextState = [false, false, false, false, false];
    setCevapDogruIkonuGorunsun(nextState);
    setCevapYanlisIkonuGorunsun(nextState);
  }

  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="row wordWord">
        <div className="col-12 AR">{props.kart_detayi.soru}</div>

        <div className="col-12 TR">
          <Form>
            {props.kart_detayi.secenekler.map((secenek, sec_index) => (
              <div key={sec_index} style={{ marginBottom: 5 }}>
                <Form.Check
                  inline
                  label={secenek}
                  name={props.kart_detayi.soru}
                  type="radio"
                  id={`${props.kart_detayi.soru}-${sec_index}`}
                  value={sec_index}
                  onClick={(e) => cevapSecildi(e)}
                />

                {cevapDogruIkonuGorunsun[sec_index] && (
                  <img
                    className="correct"
                    src="/assets/images/check-lg.svg"
                    alt="Correct"
                    width="23"
                    height="23"
                    style={{ marginLeft: -15, marginBottom: 5 }}
                  ></img>
                )}
                {cevapYanlisIkonuGorunsun[sec_index] && (
                  <img
                    className="incorrect"
                    src="/assets/images/x.svg"
                    alt="Incorrect"
                    width="25"
                    height="25"
                    style={{ marginLeft: -18, marginBottom: 2 }}
                  ></img>
                )}
              </div>
            ))}
          </Form>
        </div>
        <button
          type="button"
          className="btn btn-link"
          style={{
            marginBottom: 8,
            textAlign: "right",
            display: "block",
          }}
          onClick={ipucuDugmesineBasildi}
        >
          kontrol et
        </button>
      </div>
    </div>
  );
}
